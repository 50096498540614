import axios from "axios";
import { UserModel } from "./_models";
import { subscribe } from "diagnostics_channel";

const API_URL_BASE = import.meta.env.VITE_APP_API_URL_BASE;
const API_URL = import.meta.env.VITE_APP_BACKEND_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL_BASE}/auth/get-user/`;
export const GET_SESSION = `${API_URL_BASE}/auth/get-session/`;
export const GET_CSRF = `${API_URL_BASE}/csrf/`;
export const LOGIN_URL = `${API_URL}/api/auth/login/`;
export const REGISTER_URL = `${API_URL}/api/auth/register/`;
export const CHECK_ORIGIN_URL = `${API_URL_BASE}/core/check-allowed-origin/`;

// export const REQUEST_PASSWORD_URL = `${API_URL}/password/reset/`;

// Server should return AuthModel
export async function login(email: string, password: string) {
  const response = await axios.post<{ key: string }>(
    LOGIN_URL,
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );
  return response;
}

export async function loginWithoutCSRF(email: string, password: string) {
  const response = await axios.post<{ key: string }>(
    LOGIN_URL,
    {
      email,
      password,
    },
  );
  return response;
}

export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
  subscribe: boolean,
  notifications: boolean,
  iAgree: boolean
) {
  return axios.post(
    REGISTER_URL,
    {
      email,
      first_name: firstname,
      last_name: lastname,
      password1: password,
      password2: password_confirmation,
      subscribe,
      notifications,
      iAgree,
    },
    { withCredentials: true }
  );
}

export function registerWithoutCSRF(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
  subscribe: boolean,
  notifications: boolean,
  iAgree: boolean
) {
  return axios.post(
    REGISTER_URL,
    {
      email,
      first_name: firstname,
      last_name: lastname,
      password1: password,
      password2: password_confirmation,
      subscribe,
      notifications,
      iAgree,
    }
  );
}

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
//     email,
//   });
// }

export function getUser(key?: string) {
  const config: {
    withCredentials: true,
    headers: { [key: string]: string }
  } = {
    withCredentials: true,
    headers: {},
  };

  if (key) {
    config.headers['Authorization'] = `Token ${key}`;
  }

  return axios.get(GET_USER_BY_ACCESSTOKEN_URL, config);
}

export function getSession() {
  return axios.get(GET_SESSION,     {
    withCredentials: true,
  });
}

export function getSessionWithKey(key?: string) {
  const config: {
    headers: { [key: string]: string }
  } = {
    headers: {},
  };

  if (key) {
    config.headers['Authorization'] = `Token ${key}`;
  }

  return axios.get(GET_SESSION, config);
}


export function checkOrigin(origin: string) {
  return axios.post(CHECK_ORIGIN_URL, {origin: origin});
}