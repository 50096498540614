import React, { FC, useEffect, useState } from "react";
import {
  applySentimentColors,
  highlightResults,
  splitWordsByHighlight,
} from "../../../../core/_helpers";
import {
  EventWordModel,
  SemanticSearchResult,
  TextSearchResult,
} from "../../../../core/_models";
import { WordModel } from "../../../../../transcript/core/_models";

interface ChapterProps {
  shouldApplySentimentAnalysis: boolean;
  chapterWords: EventWordModel[];
  timerTime: React.MutableRefObject<number>;
  shouldApplyHighlight: boolean;
  chapterStart: number;
  chapterEnd: number;
  seekedTime: number;
  currentIndex: number;
  results?: (TextSearchResult | SemanticSearchResult)[];
  onWordClick: (time: number) => void;
  highlightTags: boolean;
}

const Chapter: FC<ChapterProps> = ({
  chapterStart,
  chapterEnd,
  chapterWords,
  seekedTime,
  shouldApplySentimentAnalysis,
  timerTime,
  onWordClick,
  results,
  shouldApplyHighlight,
  currentIndex,
  highlightTags,
}) => {
  const [words, setWords] = useState<EventWordModel[]>(chapterWords);
  const [playerTime, setPlayerTime] = useState<number>(0);

  useEffect(() => {
    setWords(chapterWords);
  }, [chapterWords]);

  useEffect(() => {
    if (seekedTime !== undefined) {
      if (seekedTime > Number(chapterStart)) {
        setPlayerTime(seekedTime);
        scrollToWord(seekedTime);
      } else {
        setPlayerTime(0);
      }
    }
  }, [seekedTime]);

  useEffect(() => {
    let previousTime = timerTime.current * 1000;

    const intervalId = setInterval(() => {
      if (timerTime.current * 1000 !== previousTime) {
        if (
          timerTime.current * 1000 >= chapterStart &&
          timerTime.current * 1000 <= chapterEnd
        ) {
          setPlayerTime(timerTime.current * 1000);
        }
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  const scrollToWord = (time: number) => {
    const wordToScroll = words.reduce((closestWord, currentWord) => {
      const currentWordDistance = Math.abs(currentWord.start - time);
      const closestWordDistance = Math.abs(closestWord.start - time);

      return currentWordDistance < closestWordDistance
        ? currentWord
        : closestWord;
    }, words[0]);

    if (wordToScroll) {
      var element = document.getElementById(wordToScroll.start.toString());
      var scollableElement = document.getElementById("srollable-transcript");
      if (element) {
        if (element && scollableElement) {
          const containerHeight = scollableElement.clientHeight;
          scollableElement.scrollTop =
            element.offsetTop - containerHeight / 2 - 100;
        }
      }
    }
  };

  const renderWords = () => {
    var wordsCopy = [...words];
    const wordElements = [];

    for (let i = 0; i < wordsCopy.length; i++) {
      let isPartial = false;
      if (shouldApplyHighlight && results && results.length > 0) {
        for (let j = 0; j < results.length; j++) {
          if (results[j].end == wordsCopy[i].end && results[j].isPartial) {
            isPartial = true;
            const splitWords = splitWordsByHighlight(wordsCopy[i], results[j]);
            const splitWordElement = createWordElement(
              splitWords.original,
              i,
              splitWords.split,
              isPartial
            );
            wordElements.push(splitWordElement);
            break;
          }
        }
      }
      if (!isPartial) {
        const wordElement = createWordElement(wordsCopy[i], i);
        wordElements.push(wordElement);
      }
    }

    return wordElements;
  };

  const createWordElement = (
    word: EventWordModel,
    index: number,
    splitWords?: WordModel[],
    isPartial?: boolean
  ) => {
    return (
      <span
        key={index}
        className={`${
          word.text == "" ? "hidden" : "highlights-wrapper"
          // {
          //     highlightTags
          //       ? word.tags && word.tags!.length > 0
          //         ? `color-${word.tags![0].color.slice(1)}`
          //         : ""
          //       : ""
          //   }
        }`}
        onClick={() => onWordClick(word.start)}
        data-start={word.start}
        data-end={word.end}
        data-text={word.text}
        data-speaker={word.speaker}
      >
        <>
          {isPartial && splitWords && splitWords.length > 1 ? (
            splitWords?.map((partialWord, index) => {
              return (
                <span
                  key={partialWord.start}
                  data-start={partialWord.start}
                  data-end={partialWord.end}
                  id={partialWord.start.toString()}
                  className={`event-word ${
                    shouldApplyHighlight
                      ? highlightResults(partialWord, results, currentIndex)
                      : ""
                  }
                    ${
                      shouldApplySentimentAnalysis
                        ? applySentimentColors(partialWord)
                        : ""
                    }`}
                  style={{
                    color: playerTime >= word.start ? "var(--bs-primary)" : "",
                    paddingRight:
                      index === splitWords.length - 1 ? "3px" : "0px",
                    borderLeft: "0px",
                    borderRight: "0px",
                  }}
                >
                  {partialWord.text}
                </span>
              );
            })
          ) : (
            <span
              id={word.start.toString()}
              data-start={word.start}
              data-end={word.end}
              className={`event-word ${
                shouldApplyHighlight
                  ? highlightResults(word, results, currentIndex)
                  : ""
              } ${
                shouldApplySentimentAnalysis ? applySentimentColors(word) : ""
              }`}
              onClick={() => onWordClick(word.start)}
              style={{
                color: playerTime >= word.start ? "var(--bs-primary)" : "",
                paddingRight: "3px",
                borderLeft: "0px",
                borderRight: "0px",
              }}
            >
              {word.text}
            </span>
          )}
        </>
      </span>
    );
  };

  return <div className="chapter">{renderWords()}</div>;
};

export default Chapter;
