import { FC } from "react";
import { PastEventModel, SpeakerModel } from "../core/_models";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import PastEvent from "./PastEvent";

interface SidebarProps {
  speakers: SpeakerModel[];
  linktoSlides: string | undefined;
  linktoReport: string | undefined;
  agenda: string[] | undefined;
  pastEvents: PastEventModel[];
  company: string;
  eventId: string;
}

const Sidebar: FC<SidebarProps> = ({
  speakers,
  linktoSlides,
  linktoReport,
  agenda,
  pastEvents,
  company,
  eventId,
}) => {
  return (
    <>
      <div className="links ">
        {linktoSlides || linktoReport ? <h4>Downloads:</h4> : null}
        <div className="d-flex">
          {linktoSlides && (
            <a
              className="btn btn-secondary btn-sm mb-3"
              href={linktoSlides}
              target="_blank"
            >
              Slides
            </a>
          )}
          {linktoReport && (
            <a
              className="btn btn-secondary btn-sm mb-3"
              href={linktoReport}
              target="_blank"
            >
              Report
            </a>
          )}
        </div>
      </div>
      {agenda && agenda.length > 0 && (
        <div className="accordion mb-5" id="kt_accordion_1">
          <div className="accordion-item">
            <h2 className="accordion-header" id="kt_accordion_1_header_1">
              <button
                className="accordion-button fs-4 fw-bold collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_1_body_1"
                aria-expanded="false"
                aria-controls="kt_accordion_1_body_1"
              >
                Agenda
              </button>
            </h2>
            <div
              id="kt_accordion_1_body_1"
              className="accordion-collapse collapse"
              aria-labelledby="kt_accordion_1_header_1"
              data-bs-parent="#kt_accordion_1"
            >
              <div className="accordion-body">
                <ul className="mb-0">
                  {agenda.map((item) => {
                    return <li key={item}>{item}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">How does this Q&A work:</h3>
        </div>
        <div className="card-body">
          <p>
            This company invites all interested, both current investors and
            potential investors to participate in this event, ask questions
            directly on this page before the event starts and upvote those
            questions you would like addressed.
          </p>
          <p>
            The company will address as many questions as possible, and focus on
            those questions most wanted by investors.
          </p>
        </div>
      </div>
      <div className="card card-custom mt-5">
        <div className="card-header">
          <div className="card-title">Speakers at this event:</div>
        </div>
        <div className="card-body">
          {speakers &&
            speakers.length > 0 &&
            speakers.map((speaker: SpeakerModel) => {
              return (
                <div key={speaker.name} className="speaker row">
                  <div className="symbol symbol-50px col-3">
                    <img
                      alt="Speaker avatar"
                      src={
                        speaker.avatar
                          ? speaker.avatar
                          : toAbsoluteUrl("media/avatars/blank.png")
                      }
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null;
                        target.src = toAbsoluteUrl("media/avatars/blank.png");
                      }}
                      style={{ objectFit: "contain" }}
                    ></img>
                  </div>
                  <div className="col">
                    <h3>{speaker.name}</h3>
                    <p>{speaker.role}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {pastEvents && (
        <div className="card card-custom mt-5">
          <div className="card-header m-0">
            <h3 className="card-title">Past events from {company}:</h3>
          </div>
          <div className="card card-body past-events-wrapper p-0">
            {pastEvents.length > 0 &&
              pastEvents.map((pastEvent, index) => {
                return (
                  <div
                    key={pastEvent.id}
                    className={`mt-3 ${
                      index < pastEvents.length - 1 ? "" : "mb-3"
                    }`}
                  >
                    <PastEvent
                      eventId={eventId}
                      index={index}
                      pastEvent={pastEvent}
                    ></PastEvent>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
