import { FC, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import React from "react";
import Questions from "./components/Questions";
import { EventModel } from "./core/_models";
import { SuspensedView } from "../../routing/PrivateRoutes";
import { systemMode, useThemeMode } from "../../../_metronic/partials";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import NotificationsContainer, {
  Color,
  NotificationsContainerHandle,
} from "../../../_metronic/partials/notification/Notification";
import {
  calculateCountdown,
  formatDate,
  getLanguageNames,
} from "./core/_helpers";
import { useAuth } from "../../modules/auth";
import { getEventById, getEventByIdWithKey } from "./core/_requests";
import EventScheduled from "./layouts/EventScheduled";
import EventLive from "./layouts/EventLive";
import EventEnded from "./layouts/EventEnded";
import { Helmet } from "react-helmet";
import { useLayout } from "../../../_metronic/layout/core";

const getEvent = async (eventId: string, key?: string) => {
  try {
    let response = await getEventByIdWithKey(eventId, key);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const EventEmbedded: FC = () => {
  const { currentUser, key } = useAuth();
  const navigate = useNavigate();
  const { customBtnColor, customTextColor, mode } = useThemeMode();
  const { config, setLayout } = useLayout();

  const { eventId } = useParams<{ eventId: string }>();
  const notificationsRef = useRef<NotificationsContainerHandle | null>(null);

  const [event, setEvent] = useState<EventModel>({} as EventModel);
  const [initCountDown, setInitCountDown] = useState({
    countDown: "",
    startRefreshing: false,
  });
  const [triggerSignUpModal, setTriggerSignUpModal] = useState<boolean>(false);
  const [shouldPostQuestion, setShouldPostQuestion] = useState<boolean>(false);
  const [tabs, setTabs] = useState<string[]>([
    "Back",
    "Recording",
    "Questions",
  ]);
  const [isUserParticipating , setIsUserParticipating] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>("Recording");
  const [shouldUpvoteQuestion, setShouldUpvoteQuestion] =
    useState<boolean>(false);
  const [embeded, setEmbeded] = useState<boolean>(false);


  const {
    data: fetchedEvent,
    refetch: refetchEvent,
    isError,
  } = useQuery(["event", eventId], () => getEvent(eventId!, key), {
    suspense: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const match = useMatch("/app/event/:eventId");

  const isEmbeded = useMatch("/app/event/embeded/:eventId");

  useEffect(() => {
    if (isEmbeded) {
      setEmbeded(true);
    }
  }, [fetchedEvent]);

  useEffect(() => {
    if (currentUser) {
      refetchEvent();
    }
  }, [currentUser, isUserParticipating]);

  useEffect(() => {
    if (fetchedEvent) {
      if (match) {
        window.history.pushState(
          {},
          "",
          `/app/${fetchedEvent.author_slug}/event/${eventId}/${fetchedEvent.event_slug}`
        );
      }
      var formatedDate = formatDate(fetchedEvent.start_date);
      fetchedEvent.start_date = formatedDate;
      if (
        fetchedEvent.status === "ARCHIVED" &&
        currentUser &&
        event.has_transcript && event.user_participation
      ) {
        setTabs(["Back", "Recording", "Transcript", "Questions"]);
      }

      const params = new URLSearchParams(location.search);
      const action = params.get('action');

      if (action === 'showQuestions') {
        setCurrentTab("Questions");
        window.history.pushState({}, "", `/app/event/embeded/${eventId}`);
      }

    }
  }, [event, isUserParticipating]);

  useEffect(() => {
    if (fetchedEvent) {
      setEvent(fetchedEvent);
      setIsUserParticipating(fetchedEvent.user_participation);
    }
  }, [fetchedEvent]);

  if (isError) {
    return <></>;
  }

  useEffect(() => {
    const eventTitle = document.getElementById("header-event-title");
    let newConfig = { ...config };
    if (event.author__white_label_logo_url) {
      newConfig.app!.header!.logoUrl = event.author__white_label_logo_url;
    } else {
      newConfig.app!.header!.logoText = event.author;
    }
    setLayout(newConfig);
    if (eventTitle) {
      eventTitle.textContent = event.title;
    }
  }, [event]);

  const createNewNotification = (color: Color, message: string) => {
    if (!notificationsRef.current) return;
    notificationsRef.current?.createNotification(color, message);
  };

  const updateUserParticipation = (isUserParticipating: boolean) => {
    var newEvent = { ...event };
    setIsUserParticipating(isUserParticipating);
    newEvent.user_participation = isUserParticipating;
    setEvent(newEvent);
  };

  useEffect(() => {
    const initCountDown = calculateCountdown(event.start_date);
    setInitCountDown({
      countDown: initCountDown.countdown,
      startRefreshing: initCountDown.startRefreshing,
    });
  }, [event.start_date]);

  const changeTab = (tab: string) => {
    setCurrentTab(tab);
  };

  return (
    <div className="d-flex flex-column p-0">
      <div
        className="position-fixed top-0 d-flex justify-content-evenly w-100"
        style={{ zIndex: 9999 }}
      >
        {tabs.map((tab) => (
          <React.Fragment key={tab}>
            {tab === "Back" ? (
                <a
                  onClick={() => event.author_slug ? navigate(`/app/company/embedded/${event.author_slug}/events`) : window.history.back()}
                  className={`btn btn-sm rounded-0 p-0 ps-3 pe-3 d-flex justify-content-center align-items-center fw-bold border-bottom border-1 ${
                    mode == "light" ? "border-dark btn-light" : "border-light btn-dark text-white"
                  }`}
                >
                  <i className="ki-outline ki-arrow-left fs-2x p-0"></i>
                  All events
                </a>
            ) : (
              <a
                onClick={() => setCurrentTab(tab)}
                className={`btn btn-sm flex-fill rounded-0 ${
                  currentTab == tab
                    ? customBtnColor
                      ? "btn-iframe-custom " + customTextColor
                      : "btn-ribbon-lang-active btn-primary"
                    : customBtnColor
                    ? "btn-iframe-custom-darker " + customTextColor
                    : "btn-light-primary btn-active-light-primary"
                }`}
                style={
                  customBtnColor ? { "--custom-btn-color": customBtnColor } : {}
                }
              >
                {tab}
              </a>
            )}
          </React.Fragment>
        ))}
      </div>
      {currentTab === "Recording" && (
        <>
          {(event.status === "SCHEDULED" ||
            (event.status === "LIVE" && !event.user_participation) ||
            currentUser == undefined ||
            (event.status === "ARCHIVED" && !event.user_participation) ||
            currentUser == undefined ||
            (event.status === "LIVE" &&
              event.streamed_on_stokk_io == false)) && (
            <EventScheduled
              embedded={true}
              currentUser={currentUser}
              onRefreshEvent={refetchEvent}
              eventStatus={event.status}
              eventId={eventId!}
              title={event.title}
              author={event.author}
              streamedOnStokkIo={event.streamed_on_stokk_io}
              startDate={event.start_date}
              postQuestion={setShouldPostQuestion}
              triggerSignUpModal={triggerSignUpModal}
              userParticipating={event.user_participation}
              createNotification={createNewNotification}
              externalJoinLink={event.external_join_link}
              initialCountdown={initCountDown.countDown}
              changeTab={changeTab}
              handleUserParticipating={updateUserParticipation}
            ></EventScheduled>
          )}

          {event.status === "ARCHIVED" &&
            event.user_participation == true &&
            currentUser && (
              <EventEnded
                tab={currentTab}
                hasTranscript={event.has_transcript}
                availableLanguages={getLanguageNames(
                  event.transcript_languages
                )}
                speakers={event.speakers}
                embedded={true}
                createNotification={createNewNotification}
                company={event.author}
                videoUrl={event.recording_url}
                eventId={eventId!}
                eventTitle={event.title}
                startDate={event.start_date}
                transcriptPdf={event.transcript_pdf_url}
                transcriptSentimentPdf={event.transcript_pdf_sentiment_url}
                linkToReport={event.report_url}
                linkToSlides={event.slides_url}
              ></EventEnded>
            )}
        </>
      )}

      {((event.status === "LIVE" &&
        event.user_participation == true &&
        currentUser) ||
        (event.status == "ARCHIVED" && event.qna_closed == false)) && (
        <EventLive
          eventEnd={event.end_date}
          playbackUrl={event.live_stream_url}
          embedded={true}
          tab={currentTab}
        ></EventLive>
      )}

      {currentTab == "Transcript" && (
        <EventEnded
          tab={currentTab}
          hasTranscript={event.has_transcript}
          availableLanguages={getLanguageNames(event.transcript_languages)}
          embedded={true}
          createNotification={createNewNotification}
          company={event.author}
          videoUrl={event.recording_url}
          eventId={eventId!}
          eventTitle={event.title}
          startDate={event.start_date}
          transcriptPdf={event.transcript_pdf_url}
          transcriptSentimentPdf={event.transcript_pdf_sentiment_url}
          linkToReport={event.report_url}
          linkToSlides={event.slides_url}
        ></EventEnded>
      )}

      {currentTab == "Questions" && (
        <SuspensedView additionalFallback={<QuestionsFallback />}>
          <Questions
            pastEvents={event.past_events}
            eventTitle={event.title}
            qnaClosed={event.qna_closed}
            videoUrl={event.recording_url}
            embedded={true}
            company={event.author}
            eventId={eventId!}
            isModerated={event.moderation_enabled ? true : false}
            shouldPostQuestion={shouldPostQuestion}
            shouldUpvoteQuestion={shouldUpvoteQuestion}
            isSignedUp={event.user_participation}
            agenda={event.agenda}
            speakers={event.speakers}
            eventStatus={event!.status}
            linkToReport={
              event.status != "ARCHIVED" ? event.report_url : undefined
            }
            linkToSlides={
              event.status != "ARCHIVED" ? event.slides_url : undefined
            }
            createNotification={createNewNotification}
            onUpvoteSignUp={() => {
              setTriggerSignUpModal(true);
              setShouldUpvoteQuestion(true);
              setTimeout(() => setTriggerSignUpModal(false), 1000);
            }}
            onSignUp={() => {
              setTriggerSignUpModal(true);
              setShouldPostQuestion(true);
              setTimeout(() => setTriggerSignUpModal(false), 1000);
            }}
          />
        </SuspensedView>
      )}
      <NotificationsContainer ref={notificationsRef} />
    </div>
  );
};

const QuestionsFallback: React.FC = () => {
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  return (
    <div className="d-flex justify-content-center" style={{ height: "75vh" }}>
      <div className="d-flex flex-column justify-content-center">
        {calculatedMode === "dark" ? (
          <img
            className="w-75 align-self-center"
            alt="Logo Stokk.io"
            src={toAbsoluteUrl("media/logos/stokkio/logo-white-bubble.svg")}
          />
        ) : (
          <img
            alt="Logo Stokk.io"
            src={toAbsoluteUrl("media/logos/stokkio/logo-black-bubble.svg")}
          />
        )}
        <p className="font-weight-bold mt-4 align-self-center text-muted fs-5">
          Loading the questions ...
        </p>
      </div>
    </div>
  );
};

export default EventEmbedded;
