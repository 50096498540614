import { createPortal } from "react-dom";
import createContainer from "../../helpers/NotificationHelper";
import {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import React from "react";

const container = createContainer();

export type Color = "info" | "success" | "warning" | "error";

interface NotificationProps {
  color: Color;
  children: ReactNode;
  onClose: () => void;
}

interface NotificationContainerData {
  color: Color;
  id: number;
  message: string;
}

export interface NotificationsContainerHandle {
  createNotification: (color: Color, message: string) => void;
}

function Notification({ color, children, onClose }: NotificationProps) {
  const [isClosing, setIsClosing] = React.useState(false);
  const [grow, setGrow] = React.useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(()=>setGrow(""), 100);
    setTimeout(onClose, 500);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setGrow("grow");
    }, 50);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const notificationClass =
    color === "error"
      ? "bg-light-danger text-danger"
      : color === "success"
      ? "bg-light-success text-success"
      : color === "warning"
      ? "bg-light-warning text-warning"
      : "bg-light-primary text-primary";

  const notificationTitle =
    color === "error"
      ? "Something went wrong!"
      : color === "success"
      ? "Success!"
      : color === "warning"
      ? "Warning!"
      : "Information!";

  const notificationIcon =
    color === "error"
      ? "ki-outline ki-cross text-danger"
      : color === "success"
      ? "ki-outline ki-check text-success"
      : color === "warning"
      ? "ki-outline ki-information-3 text-warning"
      : "ki-outline ki-information-4 text-primary";

  const notificationTextColor =
    color === "error"
      ? "text-danger"
      : color === "success"
      ? "text-success"
      : color === "warning"
      ? "text-warning"
      : "text-primary";

  return createPortal(
    <div className={`notification-wrapper ${grow}`}>
      <div
      className={`notification alert d-flex flex-column flex-sm-row p-5 me-5 ms-5 mb-5 ${notificationClass} ${
        isClosing ? "slideOut" : "slideIn"
      }`}
    >
      <i className={`notification-icon me-5 ${notificationIcon}`}></i>
      <div className="d-flex flex-column pe-0 pe-sm-10">
        <h5 className="mb-1">{notificationTitle}</h5>
        <span>{children}</span>
      </div>
      <button
        type="button"
        onClick={handleClose}
        className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
      >
        <i
          className={`ki-outline ki-abstract-11 fs-1 ${notificationTextColor}`}
        ></i>
      </button>
    </div>
    </div>
    ,
    container
  );
}

const NotificationsContainer = forwardRef<NotificationsContainerHandle, {}>(
  (props, ref) => {
    const [notifications, setNotifications] = React.useState<
      NotificationContainerData[]
    >([]);
    const nextId = React.useRef(0);

    const createNotification = (color: Color, message: string) => {
      const newNotification: NotificationContainerData = {
        color: color,
        id: nextId.current++,
        message: message,
      };

      setNotifications((prevNotifications) => [
        ...prevNotifications,
        newNotification,
      ]);
    };

    const removeNotification = (id: number) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id)
      );
    };

    useImperativeHandle(ref, () => ({
      createNotification,
    }));

    return (
      <>
        {notifications.map(({ id, color, message }) => (
          <Notification
            key={id}
            color={color}
            onClose={() => removeNotification(id)}
          >
            {message}
          </Notification>
        ))}
      </>
    );
  }
);

export default NotificationsContainer;
