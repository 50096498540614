import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { HeaderWrapper } from "./components/header";
import { ScrollTop } from "./components/scroll-top";
import { Content } from "./components/content";
import { FooterWrapper } from "./components/footer";
import { Sidebar } from "./components/sidebar";

import { PageDataProvider } from "./core";
import { FooterEmbedded } from "./components/footer/FooterEmbedded";

const EmbeddedLayout = () => {
  return (
    <div className="w-100 h-100" id="kt_app_root">
      <Outlet />
      <FooterEmbedded />
    </div>
  );
};

export { EmbeddedLayout };
