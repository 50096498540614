import { FC, useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import {
  SavedQuestionModel,
  EventStatus,
  FilterOption,
  PastEventModel,
  QuestionModel,
  QuestionsModel,
  SortOption,
  SpeakerModel,
} from "../core/_models";
import {
  getQuestionsByEventId,
  getQuestionsByFilter,
  getQuestionsBySearch,
  getQuestionsBySort,
  postQuestion,
  upvoteQuestion,
} from "../core/_requests";
import { useQuery } from "react-query";
import Question from "./Question";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../modules/auth";
import { Color } from "../../../../_metronic/partials/notification/Notification";
import AnswerModal from "../../../../_metronic/partials/modals/event/AnswerModal";
import DeletedQuestionWrapper from "./DeletedQuestionWrapper";
import { KTSVG } from "../../../../_metronic/helpers";
import { useThemeMode } from "../../../../_metronic/partials";

const redirectBase = import.meta.env.VITE_APP_BACKEND_URL;

const getQuestions = async (
  eventId: string,
  key?: string
): Promise<QuestionsModel | null> => {
  try {
    let response = await getQuestionsByEventId(eventId, key);

    response.data.questions.sort((a: QuestionModel, b: QuestionModel) =>
      a.asked_live || b.asked_live
        ? a.asked_live && !b.asked_live
          ? 1
          : -1
        : a.upvotes_count > b.upvotes_count
        ? 1
        : b.upvotes_count > a.upvotes_count
        ? -1
        : new Date(a.posted_on).getDate() < new Date(b.posted_on).getDate()
        ? -1
        : new Date(a.posted_on).getDate() > new Date(b.posted_on).getDate()
        ? 1
        : 0
    );

    response.data.questions.sort((a: QuestionModel, b: QuestionModel) =>
      a.status !== undefined && b.status !== undefined
        ? (((a.status == "A" && (b.status == "P" || b.status == "D")) ||
            (a.status == "D" && b.status == "P")) &&
            -1) ||
          ((((a.status == "P" || a.status == "D") && b.status == "A") ||
            (a.status == "P" && b.status == "D")) &&
            1) ||
          0
        : 0
    );

    return response.data as QuestionsModel;
  } catch (error) {
    return null;
  }
};

interface QuestionsProps {
  eventId: string;
  speakers: SpeakerModel[];
  eventStatus: EventStatus;
  company: string;
  agenda: string[] | undefined;
  linkToSlides: string | undefined;
  linkToReport: string | undefined;
  eventTitle: string;
  videoUrl?: string;
  isSignedUp: boolean;
  qnaClosed?: boolean;
  shouldPostQuestion: boolean;
  shouldUpvoteQuestion: boolean;
  isModerated: boolean;
  pastEvents: PastEventModel[];
  embedded?: boolean;
  createNotification: (color: Color, message: string) => void;
  onSignUp: () => void;
  onUpvoteSignUp: () => void;
}

const Questions: FC<QuestionsProps> = ({
  eventId,
  speakers,
  eventStatus,
  linkToReport,
  company,
  linkToSlides,
  createNotification,
  agenda,
  pastEvents,
  isSignedUp,
  eventTitle,
  videoUrl,
  shouldPostQuestion,
  shouldUpvoteQuestion,
  onSignUp,
  qnaClosed,
  onUpvoteSignUp,
  isModerated,
  embedded,
}) => {
  const { customBtnColor, customTextColor } = useThemeMode();
  const { key } = useAuth();
  const [questions, setQuestions] = useState<QuestionsModel>(
    {} as QuestionsModel
  );
  const [charCount, setCharCount] = useState<number>(700);
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [inputAreaValue, setInputAreaValue] = useState<string>("");
  const [askAnonymously, setAskAnonymously] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [prevQuery, setPrevQuery] = useState<string>("");
  const [sortValue, setSortValue] = useState<string>("Default");
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>("");
  const [showAnswerModal, setShowAnswerModal] = useState<boolean>(false);
  const [isTextValid, setIsTextValid] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const [upvotedQuestion, setUpvotedQuestion] = useState<
    QuestionModel | undefined
  >(undefined);
  const [questionToUpvote, setQuestionToUpvote] = useState<number | undefined>(
    undefined
  );
  const [selectedQuestion, setSelectedQuestion] = useState<
    QuestionModel | undefined
  >();
  const [savedQuestion, setSavedQuestion] = useState<SavedQuestionModel>(
    {} as SavedQuestionModel
  );
  const [questionsLeft, setQuestionsLeft] = useState<number>(3);
  const postBtnRef = useRef<HTMLButtonElement | null>(null);

  const location = useLocation();
  const { currentUser } = useAuth();

  const { data: fetchedQuestions, error } = useQuery(
    ["eventId", eventId],
    () => getQuestions(eventId!, key),
    {
      suspense: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  useEffect(() => {
    if (fetchedQuestions !== null && fetchedQuestions !== undefined) {
      setQuestions(fetchedQuestions);
      setTotalQuestions(fetchedQuestions.total_questions);
      setQuestionsLeft(fetchedQuestions.questions_left);
      setLoading(false);
      setFilterValue("All");
      setSortValue("Default");
      setSearchQuery("");
    } else {
      setHasError(true);
    }
  }, [fetchedQuestions]);

  useEffect(() => {
    const postSavedQuestionAfterSignUp = async () => {
      postBtnRef.current?.setAttribute("data-kt-indicator", "on");
      if (savedQuestion.eventId) {
        let response = await postQuestion(
          savedQuestion.eventId,
          savedQuestion.question_body,
          savedQuestion.is_anonymous,
          currentUser?.email,
          key
        );
        if (response.status === 201) {
          createNotification("success", "Question posted successfully");
          setSavedQuestion({} as SavedQuestionModel);
          setQuestionsLeft(response.data.questions_left);
          setTextAreaValue("");
          setCharCount(700);
          setAskAnonymously(false);

          let newQuestion = response.data as QuestionModel;

          setQuestions({
            ...questions,
            questions: [...questions.questions, newQuestion],
          });
        } else {
          createNotification(
            "error",
            "Error when posting question. Please try again later."
          );
        }
        postBtnRef.current?.setAttribute("data-kt-indicator", "off");
      }
    };

    if (isSignedUp && shouldPostQuestion) {
      postSavedQuestionAfterSignUp();
    }

    if (isSignedUp && shouldUpvoteQuestion) {
      if (questionToUpvote !== undefined) {
        upvoteQuestionAfterSignUp();
      }
    }
  }, [isSignedUp]);

  const upvoteQuestionAfterSignUp = async () => {
    try {
      let response = await upvoteQuestion(questionToUpvote!, key);
      if (response.status === 200) {
        setUpvotedQuestion(response.data as QuestionModel);
      }
    } catch {
      createNotification(
        "error",
        "Error when upvoting question. Please try again later."
      );
    }
  };

  useEffect(() => {
    var search = document.getElementById("search");
    if (search) {
      var icon = search.querySelector("i");
      if (searchQuery.trim().length > 0) {
        search.classList.add("cursor-pointer");
        search.style.backgroundColor = "var(--bs-primary)";
        if (icon) {
          icon.style.color = "var(--bs-white)";
        }
      } else {
        search.classList.remove("cursor-pointer");
        search.style.backgroundColor = "var(--bs-light)";
        if (icon) {
          icon.style.color = "var(--bs-gray-500)";
        }
      }
    }
  }, [searchQuery]);

  const searchQuestions = async () => {
    try {
      if (searchQuery.trim().length > 0) {
        setLoading(true);
        setPrevQuery(searchQuery.trim());
        var response = await getQuestionsBySearch(eventId, searchQuery.trim(), key);
        setQuestions(response.data as QuestionsModel);
        setSortValue("Default");
        setFilterValue("All");
        setIsFilter(false);
        setIsSearch(true);
        setIsFilterApplied(true);
        setLoading(false);
        setSearchQuery("");
      }
    } catch {
      setSortValue("Default");
      setFilterValue("All");
      setQuestions(fetchedQuestions!);
      setIsSearch(true);
      setIsFilterApplied(true);
      setLoading(false);
      setSearchQuery("");
      createNotification(
        "error",
        "Error when searching for questions. Please try again later."
      );
    }
  };

  const sortQuestions = async (sortOption: string) => {
    try {
      setLoading(true);
      var parsedSortOption = sortOption.toLowerCase() as SortOption;
      var response = await getQuestionsBySort(eventId, parsedSortOption);
      setQuestions(response.data as QuestionsModel);
      setFilterValue("All");
      setIsSearch(false);
      setIsFilterApplied(true);
      setLoading(false);
    } catch {
      setSortValue("Default");
      setFilterValue("All");
      setQuestions(fetchedQuestions!);
      setIsSearch(false);
      setIsFilterApplied(true);
      setLoading(false);
      createNotification(
        "error",
        "Error when sorting questions. Please try again later."
      );
    }
  };

  const filterQuestions = async (filterOption: string) => {
    try {
      setLoading(true);
      var parsedFilterOption = filterOption.toLowerCase() as FilterOption;
      var response = await getQuestionsByFilter(eventId, parsedFilterOption);
      setQuestions(response.data as QuestionsModel);
      setSortValue("Default");
      setIsFilter(true);
      setIsFilterApplied(true);
      setLoading(false);
    } catch {
      setSortValue("Default");
      setFilterValue("All");
      setQuestions(fetchedQuestions!);
      setIsFilter(false);
      setIsFilterApplied(false);
      setLoading(false);
      createNotification(
        "error",
        "Error when filtering questions. Please try again later."
      );
    }
  };

  const updateCharCount = (count: number) => {
    setCharCount(700 - count);
  };

  const clearFilter = () => {
    setIsFilterApplied(false);
    setPrevQuery("");
    setFilterValue("All");
    setSortValue("Default");
    setQuestions(fetchedQuestions!);
  };

  const handlePostQuestion = async () => {
    if (textAreaValue.length > 700) {
      return;
    }

    let response;

    if (!currentUser) {
      let question_body = textAreaValue;
      let is_anonymous = askAnonymously;
      let author_email = inputAreaValue;

      let valid = author_email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

      if (valid) {
        setIsValid(true);
      } else {
        setIsValid(false);
        if (author_email.length == 0) {
          createNotification("error", "Please provide your email to cotinue.");
          return;
        } else {
          createNotification("error", "Incorrect email format.");
          return;
        }
      }

      if (textAreaValue.trim() === "") {
        setIsTextValid(false);
        return;
      }

      postBtnRef.current?.setAttribute("data-kt-indicator", "on");
      response = await postQuestion(
        eventId,
        question_body,
        is_anonymous,
        author_email,
        key
      );

      if (response.status === 201) {
        window.location.href =
          redirectBase +
          response.data.redirect_url +
          "&next=app/fe" +
          location.pathname;
      } else {
        createNotification(
          "error",
          "Error when posting question. Please try again later."
        );
      }
      postBtnRef.current?.setAttribute("data-kt-indicator", "off");
    } else {
      let question_body = textAreaValue;
      let is_anonymous = askAnonymously;

      if (textAreaValue.trim() === "") {
        setIsTextValid(false);
        return;
      }

      if (!isSignedUp) {
        setSavedQuestion({
          eventId,
          question_body,
          is_anonymous,
        } as SavedQuestionModel);
        onSignUp();
        return;
      }

      postBtnRef.current?.setAttribute("data-kt-indicator", "on");
      response = await postQuestion(eventId, question_body, is_anonymous, currentUser.email, key);

      if (response.status === 201) {
        createNotification("success", "Question posted successfully");
        setQuestionsLeft(response.data.questions_left);
        setTextAreaValue("");
        setCharCount(700);
        setAskAnonymously(false);

        let newQuestion = response.data as QuestionModel;

        setQuestions({
          ...questions,
          questions: [...questions.questions, newQuestion],
        });

        setTotalQuestions((prev) => prev + 1);
      } else {
        createNotification(
          "error",
          "Error when posting question. Please try again later."
        );
      }
      postBtnRef.current?.setAttribute("data-kt-indicator", "off");
    }
  };

  const handleDeleteQuestion = (questionId: number) => {
    let newQuestions = questions.questions.filter(
      (question) => question.id !== questionId
    );
    setQuestions({ ...questions, questions: newQuestions });
    setTotalQuestions(questions.total_questions - 1);
    setQuestionsLeft((prev) => prev + 1);
  };

  const handleUpdateQuestionUpvote = (newQuestion: QuestionModel) => {
    let newQuestions = questions.questions.map((question) =>
      question.id == newQuestion.id ? newQuestion : question
    );
    setQuestions({ ...questions, questions: newQuestions });
  };

  const validateInputs = (textToValidate: string, isEmail: boolean) => {
    if (isEmail) {
      if (!currentUser) {
        let valid = textToValidate.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

        if (valid) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      }
    }
    if (!isEmail) {
      if (textToValidate.trim() === "") {
        setIsTextValid(false);
      } else {
        setIsTextValid(true);
      }
    }
  };

  const handleAnswerClick = (question: QuestionModel) => {
    if (selectedQuestion == question) {
      setShowAnswerModal(true);
    }
    setSelectedQuestion(question);
  };

  const handleUpvoteNotSignedIn = (questionId: number) => {
    setQuestionToUpvote(questionId);
    onUpvoteSignUp();
  };

  useEffect(() => {
    if (selectedQuestion) {
      setShowAnswerModal(true);
    }
  }, [selectedQuestion]);

  var questionIndex = 0;

  if (hasError) {
    return (
      <div className="w-100 d-flex justify-content-center mt-10">
        <div className="alert bg-light-danger d-flex flex-column flex-sm-row me-10 ms-10">
          <KTSVG
            path="media/icons/duotune/files/fil007.svg"
            className="svg-icon-muted svg-icon-2hx me-4 text-danger"
          />
          <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
            <h5 className="mb-1">Something went wrong!</h5>
            <span>
              We are sorry, something went wrong when retrieving questions for
              this event. Please try again later.
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${embedded ? "mt-20 mb-20" : "container p-5"}`}>
      <div id="questions-overlap" className={`d-flex md-flex-column-reverse ${embedded ? "justify-content-center w-100" : ""}`}>
        <div className={`${embedded ? "" : "questions-wrapper me-5"}`}>
          <div className="forms">
            {(eventStatus === "SCHEDULED" ||
              eventStatus === "LIVE" ||
              qnaClosed == false) && (
              <div className="card card-custom mb-5">
                <div className="card-body pb-5 pt-5">
                  {!currentUser && (
                    <div className="form-floating mb-5">
                      <input
                        className={`form-control ${
                          isValid ? "" : "is-invalid"
                        }`}
                        placeholder="Your email"
                        type="text"
                        onChange={(e) => {
                          validateInputs(e.currentTarget.value, true);
                          setInputAreaValue(e.currentTarget.value);
                        }}
                        value={inputAreaValue}
                      ></input>
                      <label>Your email</label>
                    </div>
                  )}
                  <div className="form-floating">
                    <textarea
                      onChange={(e) => {
                        updateCharCount(e.target.value.length);
                        validateInputs(e.target.value, false);
                        setTextAreaValue(e.target.value);
                      }}
                      maxLength={700}
                      value={textAreaValue}
                      className={`form-control ${
                        isTextValid ? "" : "is-invalid"
                      }`}
                      placeholder="Ask a public question"
                      id="questionTextArea"
                      style={{ height: "100px" }}
                    ></textarea>
                    <label>Ask a public question</label>
                    <p className="fs-8 mt-2">{charCount} characters left</p>
                  </div>
                  <div className="separator"></div>
                  <div className="d-flex justify-content-between">
                    <div className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        onChange={(e) => setAskAnonymously(e.target.checked)}
                        checked={askAnonymously}
                        aria-label="Ask anonymously"
                      />
                      <label className="form-check-label">
                        Ask anonymously
                      </label>
                    </div>
                    <div>
                      <button
                        ref={postBtnRef}
                        className={`btn ${
                          customBtnColor
                            ? "btn-iframe-custom " + customTextColor
                            : "btn-primary"
                        } fs-6 btn-sm mt-4`}
                        style={
                          customBtnColor
                            ? { "--custom-btn-color": customBtnColor }
                            : {}
                        }
                        disabled={questionsLeft <= 0}
                        onClick={handlePostQuestion}
                      >
                        <span className="indicator-label">Ask question</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                      <p className="fs-8 mt-2 mb-0">
                        {questionsLeft ? questionsLeft : 0} questions left
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={`filters-wrapper ${embedded ? "ps-5 pe-5" : ""}`}>
              <div className="select-wrapper">
                <div className="question-select">
                  <select
                    className="form-select rounded-pill"
                    aria-label="Select sort"
                    id="sort-select-questions"
                    value={sortValue}
                    onChange={(e) => {
                      setSortValue(e.target.value);
                      sortQuestions(e.target.value);
                    }}
                  >
                    <option value="Default">Default</option>
                    <option value="Newest">Newest</option>
                    <option value="Oldest">Oldest</option>
                    <option value="Upvotes">Upvotes</option>
                  </select>
                </div>
                <div className="question-select">
                  <select
                    className="form-select rounded-pill"
                    aria-label="Select filter"
                    value={filterValue}
                    onChange={(e) => {
                      setFilterValue(e.target.value);
                      let newFilterValue = e.target.value;
                      if (newFilterValue == "My Questions") {
                        newFilterValue = "my";
                      }
                      filterQuestions(newFilterValue);
                    }}
                  >
                    <option value="All">All</option>
                    <option value="Answered">Answered</option>
                    <option value="Unanswered">Unanswered</option>
                    <option value="My Questions">My Questions</option>
                    <option value="Upvoted">Upvoted</option>
                  </select>
                </div>
              </div>

              <div className="question-input input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                ></input>
                <span
                  className="input-group-text"
                  id="search"
                  onClick={searchQuestions}
                >
                  <i className="ki-outline ki-magnifier fs-2"></i>
                </span>
              </div>
            </div>
          </div>

          {questions && (
            <>
              <div className="questions-header">
              <p className={`text-muted fs-3 fw-light mb-1 ${embedded ? "ps-5":""}`}>
                  {totalQuestions} Questions
                </p>
                {isFilterApplied && (
                  <div className="search-info mb-0">
                    <p className="mb-0 text-inline fs-6">
                      {!isFilter
                        ? isSearch
                          ? "Searching for:"
                          : "Sort:"
                        : "Filter:"}
                    </p>
                    <p className="fw-bold mb-0 ms-1 fs-6 text-inline">
                      {!isFilter
                        ? isSearch
                          ? prevQuery
                          : sortValue
                        : filterValue}
                    </p>
                    <i
                      onClick={clearFilter}
                      className="ki-outline ki-cross fs-1 ms-1 icon-hover"
                    ></i>
                  </div>
                )}
              </div>

              {loading ? (
                <div className="indicatior-wrapper">
                  <span className="spinner-border spinner-border-large align-middle ms-5"></span>
                </div>
              ) : (
                <>
                  {questions.total_questions === 0 ? (
                    <div className="indicatior-wrapper">
                      <h1>No questions found</h1>
                    </div>
                  ) : (
                    <>
                      {questions.questions && (
                        <div className="questions">
                          {questions.questions
                            .map((question, index) => {
                              if (
                                question.status == "A" ||
                                question.status == "P" ||
                                question.status == undefined
                              ) {
                                return (
                                  <Question
                                    embedded={embedded}
                                    key={question.id}
                                    upvotedQuestion={upvotedQuestion}
                                    onQuestionUpdated={
                                      handleUpdateQuestionUpvote
                                    }
                                    onUpvoteNotSignedIn={
                                      handleUpvoteNotSignedIn
                                    }
                                    eventStatus={eventStatus}
                                    qna_closed={qnaClosed!}
                                    isModerated={isModerated}
                                    question={question}
                                    handleDeleteQuestion={handleDeleteQuestion}
                                    userParticipation={isSignedUp}
                                    currentUser={currentUser}
                                    createNotification={createNotification}
                                    onAnswerClick={handleAnswerClick}
                                    isInsideModal={false}
                                  ></Question>
                                );
                              } else {
                                questionIndex = questionIndex + 1;

                                return (
                                  <DeletedQuestionWrapper
                                    index={questionIndex}
                                    key={question.id}
                                  >
                                    <Question
                                      key={question.id}
                                      onQuestionUpdated={() => {}}
                                      upvotedQuestion={upvotedQuestion}
                                      onUpvoteNotSignedIn={
                                        handleUpvoteNotSignedIn
                                      }
                                      eventStatus={eventStatus}
                                      qna_closed={qnaClosed!}
                                      isModerated={isModerated}
                                      handleDeleteQuestion={
                                        handleDeleteQuestion
                                      }
                                      question={question}
                                      userParticipation={isSignedUp}
                                      currentUser={currentUser}
                                      createNotification={createNotification}
                                      onAnswerClick={handleAnswerClick}
                                      isInsideModal={false}
                                    ></Question>
                                  </DeletedQuestionWrapper>
                                );
                              }
                            })
                            .reverse()}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {!embedded && (
          <div className="sidebar">
            <Sidebar
              company={company}
              agenda={agenda}
              pastEvents={pastEvents}
              speakers={speakers}
              linktoReport={linkToReport}
              linktoSlides={linkToSlides}
              eventId={eventId}
            ></Sidebar>
          </div>
        )}
      </div>
      {selectedQuestion && (
        <AnswerModal
          isModerated={isModerated}
          userParticipation={isSignedUp}
          eventStatus={eventStatus}
          eventTitle={eventTitle}
          onQuestionUpdated={handleUpdateQuestionUpvote}
          company={company}
          createNotification={createNotification}
          show={showAnswerModal}
          handleClose={() => setShowAnswerModal(false)}
          question={selectedQuestion}
          videoUrl={videoUrl}
        ></AnswerModal>
      )}
    </div>
  );
};

export default Questions;
