import { FC, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  PauseIcon,
  PlayIcon,
  SeekBackward10Icon,
  SeekForward10Icon,
} from "@vidstack/react/icons";
import { Color } from "../../../../../../_metronic/partials/notification/Notification";
import { KTSVG } from "../../../../../../_metronic/helpers";
import CustomSlider from "../../../../../../_metronic/partials/modals/event/components/CustomSlider";
import { isMobile } from "../../../core/_helpers";
import { LanguageModel } from "../../../core/_models";
import TranscriptWrapper from "./TranscriptWrapper";

interface TranscriptEmbeddedProps {
  show: boolean;
  handleClose: () => void;
  playerTime: React.MutableRefObject<number>;
  createNotification: (color: Color, message: string) => void;
  videoLength: number;
  seekedTime: number;
  isPlayerPlaying: React.MutableRefObject<boolean>;
  onSeekTime: (time: number) => void;
  availableLanguages?: LanguageModel[];
  onPlay: (play: boolean) => void;
  title: string;
  company: string;
  width: number;
  eventId: string;
  hasTranscript?: boolean;
  onFailedToFetchTranscript: () => void;
}

const TranscriptEmbedded: FC<TranscriptEmbeddedProps> = ({
  show,
  handleClose,
  playerTime,
  videoLength,
  onSeekTime,
  onPlay,
  isPlayerPlaying,
  seekedTime,
  title,
  company,
  width,
  eventId,
  hasTranscript,
  onFailedToFetchTranscript,
  createNotification,
  availableLanguages,
}) => {
  const [touched, setTouched] = useState(false);
  const [seekedTimeModal, setSeekedTimeModal] = useState(0);
  const [newSliderValue, setNewSliderValue] = useState(0);
  const touchedRef = useRef(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setIsPlaying(isPlayerPlaying.current);
  }, [isPlayerPlaying]);

  useEffect(() => {
    setSeekedTimeModal(seekedTime);
  }, [seekedTime]);

  useEffect(() => {}, [show]);
  const handleSeekBack = () => {
    onSeekTime(Number(playerTime.current) * 1000 - 10000);
    setNewSliderValue(playerTime.current);
  };

  const handleSeekForth = () => {
    onSeekTime(Number(playerTime.current) * 1000 + 10000);
    setNewSliderValue(playerTime.current);
  };

  const handleWordClicked = (time: number) => {
    playerTime.current = time / 1000;
    onSeekTime(time);
    updateSliderIfPlayerStopped();
  };

  const updateSliderIfPlayerStopped = () => {
    setNewSliderValue(playerTime.current);
  };

  const handlePlay = () => {
    onPlay(true);
    setIsPlaying(true);
  };

  const handlePause = () => {
    onPlay(false);
    setIsPlaying(false);
  };

  useEffect(() => {
    touchedRef.current = touched;
  }, [touched]);

  return (
    <div
      className="d-flex flex-column bg-body w-100"
      style={{ zIndex: 100, maxHeight: "calc(100vh - 70px)", height: "calc(100vh - 50px)" }}
    >
      <TranscriptWrapper
        onFailedToFetchTranscript={onFailedToFetchTranscript}
        updateTags={false}
        embedded={true}
        createNotification={createNotification}
        availableLanguages={availableLanguages}
        eventId={eventId}
        hasTranscript={hasTranscript}
        width={width}
        onWordClick={handleWordClicked}
        playerTime={playerTime}
        seekedTime={seekedTimeModal}
      ></TranscriptWrapper>
      <div className="d-flex justify-content-center align-items-center w-100">
        {!isPlaying ? (
          <button
            className="btn btn-icon btn-secondary btn-sm me-2"
            onClick={handlePlay}
          >
            {isMobile() ? (
              <i className="ki-outline ki-to-right fs-2"></i>
            ) : (
              <PlayIcon></PlayIcon>
            )}
          </button>
        ) : (
          <button
            className="btn btn-icon btn-secondary btn-sm me-2"
            onClick={handlePause}
          >
            {isMobile() ? (
              <i className="ki-outline ki-row-vertical"></i>
            ) : (
              <PauseIcon></PauseIcon>
            )}
          </button>
        )}

        <button
          className="btn btn-icon btn-secondary btn-sm me-2"
          onClick={handleSeekBack}
        >
          {isMobile() ? (
            <i className="ki-outline ki-double-left"></i>
          ) : (
            <SeekBackward10Icon></SeekBackward10Icon>
          )}
        </button>
        <button
          className="btn btn-icon btn-secondary btn-sm me-5"
          onClick={handleSeekForth}
        >
          {isMobile() ? (
            <i className="ki-outline ki-double-right"></i>
          ) : (
            <SeekForward10Icon></SeekForward10Icon>
          )}
        </button>
        <div className="mt-2 w-75">
        <CustomSlider
            embedded={true}
            isPlaying={isPlayerPlaying}
            videoLength={videoLength}
            playerTime={playerTime}
            onSeekTime={onSeekTime}
            newSliderValue={newSliderValue}
          ></CustomSlider>
        </div>
          
      </div>
    </div>
  );
};

export default TranscriptEmbedded;
