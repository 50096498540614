import { FC, useEffect, useRef, useState } from "react";
import { useThemeMode } from "../../../layout/theme-mode/ThemeModeProvider";

export interface CustomSliderProps {
  videoLength: number;
  playerTime: React.MutableRefObject<number>;
  isPlaying: React.MutableRefObject<boolean>;
  newSliderValue: number;
  embedded?: boolean;
  onSeekTime: (time: number) => void;
}

const CustomSlider: FC<CustomSliderProps> = ({
  videoLength,
  playerTime,
  onSeekTime,
  isPlaying,
  newSliderValue,
  embedded,
}) => {
  const { customBtnColor } = useThemeMode();
  const [sliderValue, setSliderValue] = useState("0");
  const sliderRef = useRef<HTMLInputElement | null>(null);
  const touched = useRef(false);
  const currentTime = useRef("0:00:00");

  useEffect(() => {
    updatedCurrentTime();
    handleInputChange();
  }, [sliderValue]);

  useEffect(() => {
    if (isPlaying.current === false) {
      setSliderValue(newSliderValue.toString());
    }
  }, [newSliderValue]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        playerTime.current &&
        touched.current === false &&
        isPlaying.current === true
      ) {
        setSliderValue(playerTime.current.toString());
        handleInputChange();
      }
      return () => clearInterval(intervalId);
    }, 500);
  }, []);

  const handleInputChange = () => {
    const target = sliderRef.current;
    if (target) {
      const value =
        ((target.valueAsNumber - parseFloat(target.min)) /
          (parseFloat(target.max) - parseFloat(target.min))) *
        100;
      target.style.background = `linear-gradient(to right, ${
        customBtnColor || "var(--bs-primary-active)"
      } 0%, ${
        customBtnColor || "var(--bs-primary-active)"
      } ${value}%, var(--bs-gray-400) ${value}%, var(--bs-gray-400) 100%)`;
    }
  };

  const updatedCurrentTime = () => {
    let time = new Date(Number(sliderValue) * 1000)
      .toISOString()
      .substring(12, 19);
    currentTime.current = time;
  };

  return (
    <div className={`d-flex flex-column align-items-center ${embedded ? "w-100" : "w-75"}`}>
      <style>
        {`
          input[type="range"]::-webkit-slider-thumb {
             background-color: ${
               customBtnColor || "var(--bs-primary-active)"
             } !important;
          }
          input[type="range"]::-moz-range-thumb {
             background-color: ${
               customBtnColor || "var(--bs-primary-active)"
             } !important;
          }
        `}
      </style>
      <input
        ref={sliderRef}
        id="playerSlider"
        type="range"
        className="w-100"
        min="0"
        style={customBtnColor ? { "--custom-btn-color": customBtnColor } : {}}
        max={videoLength.toString()}
        value={sliderValue}
        onTouchStart={() => (touched.current = true)}
        onTouchEnd={() => {
          touched.current = false;
          onSeekTime(parseFloat(sliderValue) * 1000);
        }}
        onMouseDown={() => (touched.current = true)}
        onMouseUp={() => {
          touched.current = false;
          onSeekTime(parseFloat(sliderValue) * 1000);
        }}
        onChange={(e) => {
          setSliderValue(e.target.value);
          handleInputChange();
        }}
        step="0.1"
      ></input>
      <div className="w-100 mt-2 d-flex justify-content-between">
        <p className="fw-light fs-8 mb-0">{currentTime.current}</p>
        <p className="fw-light fs-8 mb-0">
          {new Date(videoLength * 1000).toISOString().substring(12, 19)}
        </p>
      </div>
    </div>
  );
};

export default CustomSlider;
