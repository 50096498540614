import { TranscriptModel } from "./_models";

export const saveDraft = (transcript: TranscriptModel) => {
  try {
    transcript.timestamp = new Date().toISOString();
    let draftVersion = "original"
    if(transcript.language){
      draftVersion = transcript.language;
    }
    localStorage.setItem(`draft_${transcript.event_id}_${draftVersion}`, JSON.stringify(transcript));
    return true;
  } catch (error: any) {
    return false;
  }
};

export const loadDraft = (event_id: number, version?: string): TranscriptModel | null => {
  if (!version) {
    version = "original";
  }
  try {
    const draft = localStorage.getItem(`draft_${event_id}_${version}`);
    if (draft) {
      return JSON.parse(draft);
    }
    return null;
  } catch (error: any) {
    return null;
  }
};

export const clearDraft = (event_id: number, version?: string) => {
  if (!version) {
    version = "original";
  }
  try {
    localStorage.removeItem(`draft_${event_id}_${version}`);
  } catch (error: any) {
    return false;
  }
};

export const clearOldDrafts = () => {
  try {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("draft_")) {
        let draft = localStorage.getItem(key);
        if (draft) {
          let transcript: TranscriptModel = JSON.parse(draft);
          if(transcript.timestamp == undefined){
            localStorage.removeItem(key);
          }
          let timestamp = new Date(transcript.timestamp!).getTime();
          let now = new Date().getTime();
          if (now - timestamp > 1000 * 60 * 60 * 24 * 7) {
            localStorage.removeItem(key);
          }
        }
      }
    });
  } catch (error: any) {
    return false;
  }
};
