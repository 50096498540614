import { FC, useEffect, useState } from "react";
import { AnswerFullModel } from "../core/_models";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import {
  formatAnswerTime,
  calculateTime,
  convertStringToMiliSeconds,
} from "../core/_helpers";

interface AnswerProps {
  answer: AnswerFullModel;
  company: string;
  eventTitle: string;
  onWatchAnswerClick: (timestamp: number) => void;
}

const Answer: FC<AnswerProps> = ({
  answer,
  company,
  eventTitle,
  onWatchAnswerClick,
}) => {
  const [rating, setRating] = useState<number>(0);
  const [hoveredStar, setHoveredStar] = useState(0);

  const handleMouseOver = (ratingHovered: number) => {
    setHoveredStar(ratingHovered);
  };

  const handleMouseOut = () => {
    setHoveredStar(0);
  };

  const handleRating = (rating: number) => {
    setRating(rating);
  };

  useEffect(() => {}, [rating]);

  const ratingColor = (ratingIconValue: number): string => {
    if (hoveredStar == 0) {
      if (ratingIconValue <= rating) return "var(--bs-rating-color-active)";

      return "var(--bs-rating-color-default)";
    }
    if (ratingIconValue <= hoveredStar) return "var(--bs-rating-color-active)";

    return "var(--bs-rating-color-default)";
  };

  return (
    <div className="answer-wrapper mb-5">
      <div className="answer-header d-flex justify-content-between">
        <div className="d-flex">
          <div className="symbol symbol-50px pe-3 pt-5">
            <img
              src={
                answer.avatar
                  ? answer.avatar
                  : toAbsoluteUrl("media/avatars/blank.png")
              }
              alt="Answer author avatar"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src = toAbsoluteUrl("media/avatars/blank.png");
              }}
            ></img>
          </div>
          <div className="d-flex flex-column pt-5">
            <p className="mb-0 fw-bold">
              {answer.answered_by
                ? answer.answered_by + "\u00A0" + "@" + "\u00A0"
                : answer.answered_by_name +
                  "\u00A0" +
                  "-" +
                  "\u00A0" +
                  answer.answered_by_role +
                  "\u00A0" +
                  "at" +
                  "\u00A0" +
                  company +
                  "\u00A0" +
                  "@" +
                  "\u00A0"}
              <a className="pe-2">
                {answer.video_timestamp !== null
                  ? typeof answer.video_timestamp == "number"
                    ? formatAnswerTime(answer.video_timestamp)
                    : answer.video_timestamp
                  : "0:00:00"}
              </a>
              {"in" + "\u00A0" + eventTitle}
            </p>
            <p className="text-muted">{calculateTime(answer.posted_on)}</p>
          </div>
        </div>
        <div className="d-flex flex-column watch-answer me-5">
          <a
            className="badge badge-primary text-nowrap mb-2 watch-answer-btn p-1 pe-3"
            onClick={() =>
              answer.video_timestamp !== null
                ? typeof answer.video_timestamp == "number"
                  ? onWatchAnswerClick(answer.video_timestamp)
                  : onWatchAnswerClick(
                      convertStringToMiliSeconds(answer.video_timestamp)
                    )
                : () => {}
            }
          >
            <i className="ki-duotone ki-to-right align-self-center fs-3 text-white "></i>
            Watch answer
          </a>
          {/* <div className="d-flex flex-column">
            <p className="mb-0 fw-medium text-muted rating-text">
              Rate this answer:
            </p>
            <div className="rating mt-1 align-self-center">
              {[1, 2, 3, 4, 5].map((starNum) => (
                <label
                  key={starNum}
                  className="rating-label-custom"
                  onClick={() => handleRating(starNum)}
                  onMouseOver={() => handleMouseOver(starNum)}
                  onMouseOut={handleMouseOut}
                >
                  <i
                    className="ki-duotone ki-star fs-4"
                    style={{
                      color: ratingColor(starNum),
                    }}
                  ></i>
                </label>
              ))}
            </div>
          </div> */}
        </div>
      </div>
      <div className="answer-body mt-3">{answer.answer}</div>
    </div>
  );
};

export default Answer;
