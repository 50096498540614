import { FC, useEffect, useState } from "react";
import { useAuth } from "../../../../app/modules/auth";
import { useLayout } from "../../../layout/core";
import { useLocation, useMatch } from "react-router-dom";

const ProfilePicture: FC = () => {
  const { currentUser } = useAuth();
  const { config } = useLayout();
  const [initial, setInitial] = useState<string>();
  const [isDashboard, setIsDashboard] = useState(false);
  const [logo, setLogo] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const location = useLocation();
  const company = location.pathname.split("/")[2];

  const match = useMatch("/dashboard/*");

  useEffect(() => {
    const getCurrentCompanyProps = () => {
      const currentCompany = currentUser?.companies_managed?.find(
        (value) => value.slug == company
      );
      const logo = currentCompany?.logo;
      return { logo };
    };

    if (match && currentUser) {
      const { logo } = getCurrentCompanyProps();
      if (logo) {
        setLogo(logo);
      }
      setIsDashboard(true);
    }
  }, [match]);

  return (
    <>
      {isDashboard && logo ? (
        <div
          className="cursor-pointer symbol symbol-35px symbol-md-40px"
        >
          <img
            src={logo}
            alt="Logo"
          />
        </div>
      ) : (
        <a
          className={`btn btn-icon ${
            config.isEventPage
              ? "btn btn-icon btn-icon hover-custom-color btn-custom"
              : "btn-active-light-primary btn-custom"
          }`}
        >
          <h1
            className={`fw-light align-baseline m-0 ${
              config.isEventPage ? "text-white" : ""
            }`}
          >
            {currentUser?.first_name.charAt(0)}
          </h1>
        </a>
      )}
    </>
  );
};

export { ProfilePicture };
