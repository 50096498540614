import { FC, useEffect, useRef, useState } from "react";
import { KTIcon, KTSVG } from "../../../helpers";
import { UtteranceModel } from "../../../../app/pages/transcript/core/_models";
import { Modal } from "react-bootstrap";
import { InvestorDetailsModel } from "../../../../app/pages/event/core/_models";
import { participateInEvent } from "../../../../app/pages/event/core/_requests";
import { useAuth } from "../../../../app/modules/auth";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

interface EventSignUpProps {
  companyTitle: string;
  show: boolean;
  handleClose: () => void;
  eventId: string;
  handleSubmited: (
    wasSuccessful: boolean,
    investorDetails: InvestorDetailsModel,
    message: string
  ) => void;
}

const EventSignUp: FC<EventSignUpProps> = ({
  companyTitle,
  eventId,
  handleClose,
  handleSubmited,
  show,
}) => {
  const { key } = useAuth();
  const { customBtnColor, customTextColor } = useThemeMode();
  const [isShareholder, setIsShareholder] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false);
  const submitBtnRef = useRef<HTMLAnchorElement | null>(null);

  const participate = async () => {
    if(eventId === "191" || eventId === "192"){
      setIsShareholder("No")
    }else{
      if (isShareholder !== "Yes" && isShareholder !== "No") {
        setIsValid(false);
        return;
      }
    }

    submitBtnRef.current?.setAttribute("data-kt-indicator", "on");

    let message = "";

    let ownsShares;

    if (isShareholder === "Yes") {
      ownsShares = true;
    } else {
      ownsShares = false;
    }

    let investorDetails: InvestorDetailsModel = {
      owns_shares: ownsShares,
      contact_consent: isConsentGiven,
    };

    var response = await participateInEvent(
      parseInt(eventId),
      investorDetails,
      key
    );
    if (response.status === 200) {
      handleSubmited(true, investorDetails, message);
      setIsShareholder("");
      setIsConsentGiven(false);
      setIsValid(true);
    } else {
      message = "Something went wrong. Please try again later.";
      handleSubmited(false, investorDetails, message);
    }
    handleClose();
    submitBtnRef.current?.setAttribute("data-kt-indicator", "off");
  };

  return (
    <Modal
      id="kt_modal_event_sign_up"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px"
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className="modal-header">
        <h3 className="my-0">Investor details</h3>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>
      <div className="modal-body d-flex flex-column py-lg-5 px-lg-10">
        <></>
        {eventId !== "191" && eventId !== "192" && (
          <div className="form-floating mb-7">
            <select
              className={`form-select form-select-solid ${
                isValid ? "" : "is-invalid"
              }`}
              id="floatingSelect1"
              defaultValue="Please select one option."
              onChange={(e) => {
                setIsShareholder(e.target.value);
              }}
            >
              <option
                value="Please select one option."
                style={{ display: "none" }}
              >
                Please select one option.
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <label>Do you own shares in {companyTitle}?</label>
          </div>
        )}
        <div className="mb-10">
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="signUpConsent"
              onChange={(e) => {
                setIsConsentGiven(e.target.checked);
              }}
            />
            <label className="form-check-label" htmlFor="signUpConsent">
              I agree that {companyTitle} can contact me via email regarding
              investors news and future events. This consent can be be withdrawn
              in account settings.
            </label>
          </div>
        </div>
        <a
          ref={submitBtnRef}
          className={`btn ${
            customBtnColor
              ? "btn-iframe-custom " + customTextColor
              : "btn-primary"
          } fs-4`}
          style={customBtnColor ? { "--custom-btn-color": customBtnColor } : {}}
          onClick={participate}
        >
          <span className="indicator-label">Submit</span>
          <span className="indicator-progress">
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </a>
      </div>
    </Modal>
  );
};

export { EventSignUp };
