import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth } from "../../../../app/modules/auth";
import {
  getSession,
  getSessionWithKey,
  getUser,
  login,
  loginWithoutCSRF,
} from "../../../../app/modules/auth/core/_requests";
import { captureException, setUser } from "@sentry/react";
import { Modal } from "react-bootstrap";
import { Color } from "../../notification/Notification";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

const BASE_URL = import.meta.env.VITE_APP_BACKEND_URL;

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

interface LoginModalProps {
  show: boolean;
  handleClose: () => void;
  email: string;
  embedded?: boolean;
  createNewNotification: (color: Color, message: string) => void;
}

const LoginModal: FC<LoginModalProps> = ({
  show,
  handleClose,
  email,
  embedded,
  createNewNotification,
}) => {
  const [loading, setLoading] = useState(false);
  const { customBtnColor, customTextColor } = useThemeMode();
  const { setCurrentUser, key, saveKey } = useAuth();
  var location = useLocation();
  useEffect(() => {
    formik.setFieldValue("email", email);
  }, [email]);

  const initialValues = {
    email: "",
    password: "",
  };

  const handleLinkedInRedirect = () => {
    window.location.href = `${BASE_URL}/account/oidc/linkedin_oidc/login/?next=app/fe${location.pathname}&action=sign_up`;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      var response;
      try {
        if(embedded){
          response = await loginWithoutCSRF(values.email, values.password);
          saveKey(response.data.key);
          var { data } = await getSessionWithKey(response.data.key);
        }else{
          response = await login(values.email, values.password);
          var { data } = await getSession();
        }

        if (data.isAuthenticated) {
          if (embedded) {
            const user = await getUser(response.data.key);
            setCurrentUser(user.data);
            handleClose();
          } else {
            const url = new URL(window.location.href);
            url.searchParams.set("action", "sign_up");
            window.location.href = url.toString();
          }
        } else {
          handleClose();
          captureException(new Error("Get session failed after succesfull login"));
          createNewNotification("error", "Login failed. Please try again.");
        }
      } catch (error) {
        setCurrentUser(undefined);
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="kt_modal_event_sign_up"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px"
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className="modal-header">
        <h3 className="my-0">
          {embedded ? "Sign in with your Stokk.io account" : "Sign In"}
        </h3>
      </div>
      <div className="modal-body">
        {!embedded && (
          <div className="d-grid">
            <a
              onClick={handleLinkedInRedirect}
              className="w-100 btn btn-linkedin"
            >
              <i className="fab fa-linkedin fs-1"></i>{" "}
              <span className="fw-bold">Sign up with LinkedIn</span>
            </a>
          </div>
        )}

        {!embedded && (
          <div className="separator separator-content border-secondary my-10">
            OR
          </div>
        )}
        <form
          className="form w-100"
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_login_signin_form"
        >
          {formik.status ? (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (
            <></>
          )}

          {/* begin::Form group */}
          <div className="fv-row mb-8">
            <label className="form-label fs-6 fw-bolder text-gray-900">
              Email
            </label>
            <input
              placeholder="Email"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control bg-transparent",
                { "is-invalid": formik.touched.email && formik.errors.email },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
              type="email"
              name="email"
              autoComplete="off"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className="fv-row mb-3">
            <label className="form-label fw-bolder text-gray-900 fs-6 mb-0">
              Password
            </label>
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Wrapper */}
          <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
            <div />

            {/* begin::Link */}
            {!embedded && (
              <a
                href={
                  formik.values.email !== ""
                    ? `${BASE_URL}/account/password/reset/?email=${formik.values.email}`
                    : `${BASE_URL}/account/password/reset/`
                }
                className="link-primary pe-auto"
              >
                Forgot Password ?
              </a>
            )}
            {/* end::Link */}
          </div>
          {/* end::Wrapper */}

          {/* begin::Action */}
          <div className="d-grid">
            <button
              type="submit"
              id="kt_sign_in_submit"
              className={`btn ${
                customBtnColor
                  ? "btn-iframe-custom " + customTextColor
                  : "btn-primary"
              } fs-4`}
              style={
                customBtnColor ? { "--custom-btn-color": customBtnColor } : {}
              }
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className="indicator-label">Continue</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      </div>
    </Modal>
  );
};

export default LoginModal;
