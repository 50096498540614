import React, { useEffect, useState } from "react";
import Select, { OptionProps } from "react-select";
import { useThemeMode } from "../../../../../../../_metronic/partials";

const CheckboxOption: React.FC<OptionProps<any, true>> = ({
  innerProps,
  label,
}) => (
  <div className="p-2 highlight-select-option" {...innerProps}>
    <label>{label}</label>
  </div>
);

type HighlightSelectProps = {
  wasSearched: boolean;
  hasSentiment: boolean;
  hasTags: boolean;
  onDisplayTags: (displayTags: boolean) => void;
  onHighlightTags: (highlightTags: boolean) => void;
  onHighlightSentiment: (highlightSentiment: boolean) => void;
  onHighlightSearch: (highlightSearch: boolean) => void;
};

const HighlightSelect: React.FC<HighlightSelectProps> = ({
  wasSearched,
  hasSentiment,
  hasTags,
  onDisplayTags,
  onHighlightTags,
  onHighlightSearch,
  onHighlightSentiment,
}) => {
  const { customBtnColor, customTextColor } = useThemeMode();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [displayTags, setDisplayTags] = useState(false);
  const [highlightTags, setHighlightTags] = useState(false);
  const [highlightSentiment, setHighlightSentiment] = useState(false);
  const [highlightSearch, setHighlightSearch] = useState(false);
  const [options, setOptions] = useState<
    (null | { value: string; label: string })[]
  >([]);

  useEffect(() => {
    setOptions(
      [
        wasSearched ? { value: "search", label: "Highlight search" } : null,
        hasSentiment
          ? { value: "sentiment", label: "Highlight sentiment" }
          : null,
        hasTags ? { value: "tags", label: "Display tags" } : null,
      ].filter(Boolean)
    );
  }, [wasSearched, hasSentiment, hasTags]);

  const selectStyle = {
    base: (base: any) => ({
      ...base,
      innerHeight: "min-content",
      outerHeight: "min-content", 
    }),
    control: (base: any) => ({
      ...base,
      backgroundColor: "var(--bs-body-bg)",
      borderColor: "var(--bs-gray-300)",
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: "var(--bs-body-bg)",
      zIndex: 10,
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: customBtnColor ? "var(--custom-btn-color)": "var(--bs-primary)",
      '--custom-btn-color': customBtnColor ? customBtnColor : "var(--bs-primary)",
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: customTextColor ? customTextColor : "white",
    }),
  };

  useEffect(() => {
    onDisplayTags(displayTags);
    onHighlightTags(highlightTags);
    onHighlightSentiment(highlightSentiment);
    onHighlightSearch(highlightSearch);
  }, [displayTags, highlightTags, highlightSentiment, highlightSearch]);

  const handleSelectChange = (selected: any) => {
    setSelectedOptions(selected);
    const selectedValues = selected.map((option: any) => option.value);

    if (
      selectedValues.includes("search") ||
      selectedValues.includes("sentiment")
    ) {
      setHighlightTags(false);
      setDisplayTags(selectedValues.includes("tags"));
      setHighlightSearch(selectedValues.includes("search"));
      setHighlightSentiment(selectedValues.includes("sentiment"));
    } else {
      setHighlightTags(selectedValues.includes("tags"));
      setDisplayTags(selectedValues.includes("tags"));
      setHighlightSearch(selectedValues.includes("search"));
      setHighlightSentiment(selectedValues.includes("sentiment"));
    }
  };

  return (
    <Select
      aria-label="Highlight options"
      styles={selectStyle}
      isMulti
      options={options}
      value={selectedOptions}
      onChange={handleSelectChange}
      components={{
        Option: CheckboxOption,
      }}
      isSearchable={false}
      placeholder="Highlight options"
    />
  );
};

export default HighlightSelect;
