import { FC, useEffect, useState } from "react";
import { AnswerFullModel } from "../core/_models";
import { KTSVG } from "../../../../_metronic/helpers";
import { useQuery } from "react-query";
import Answer from "./Answer";
import { getAnswersByEventId } from "../core/_requests";
import { useAuth } from "../../../modules/auth";

const getAnswers = async (eventId: string, key?: string) => {
  try {
    let response = await getAnswersByEventId(eventId, key);
    return response.data;
  } catch {
    return null;
  }
};

interface AnswerProps {
  questionId?: number;
  company: string;
  eventTitle: string;
  onWatchAnswerClick: (timestamp: number) => void;
}

const Answers: FC<AnswerProps> = ({
  questionId,
  company,
  eventTitle,
  onWatchAnswerClick,
}) => {
  const { key } = useAuth();
  const [answers, setAnswers] = useState<AnswerFullModel[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);

  const {
    data: fetchedAnswers,
    isError,
    error,
  } = useQuery(
    ["question", questionId],
    () => getAnswers(questionId!.toString(), key),
    {
      suspense: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  useEffect(() => {
    if (fetchedAnswers) {
      setAnswers(fetchedAnswers);
    }
    if (fetchedAnswers === null) {
      setHasError(true);
    }
  }, [fetchedAnswers]);

  if (hasError) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <div className="alert bg-light-danger d-flex flex-column flex-sm-row p-5 me-10 ms-10 mb-10">
          <KTSVG
            path="media/icons/duotune/files/fil007.svg"
            className="svg-icon-muted svg-icon-2hx me-4 text-danger"
          />
          <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
            <h5 className="mb-1">Something went wrong!</h5>
            <span>
              We are sorry, we cannot retrieve answers to this question. Please
              try again later.
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="answers-wrapper">
      {answers.map((answer, index) => (
        <div key={answer.id}>
          <Answer
            answer={answer}
            company={company}
            eventTitle={eventTitle}
            onWatchAnswerClick={onWatchAnswerClick}
          ></Answer>
          {index < answers.length - 1 && <div className="separator mb-5"></div>}
        </div>
      ))}
    </div>
  );
};

export default Answers;
