import {
  SentenceModel,
  TaggedContentModel,
  TagModel,
  TagToRenderModel,
  TranscriptModel,
  UtteranceModel,
  WordModel,
} from "./_models";

export function convertPropsToNum(transcript: any): TranscriptModel {
  try {
    transcript.utterances.forEach((utterance: UtteranceModel) => {
      utterance.start =
        typeof utterance.start === "string"
          ? parseFloat(utterance.start)
          : utterance.start;
      utterance.end =
        typeof utterance.end === "string"
          ? parseFloat(utterance.end)
          : utterance.end;

      utterance.sentences.forEach((sentence: SentenceModel) => {
        sentence.start =
          typeof sentence.start === "string"
            ? parseFloat(sentence.start)
            : sentence.start;
        sentence.end =
          typeof sentence.end === "string"
            ? parseFloat(sentence.end)
            : sentence.end;

        sentence.words.forEach((word: WordModel) => {
          word.start =
            typeof word.start === "string"
              ? parseFloat(word.start)
              : word.start;
          word.end =
            typeof word.end === "string" ? parseFloat(word.end) : word.end;
          word.confidence =
            typeof word.confidence === "string"
              ? parseFloat(word.confidence)
              : word.confidence;
        });
      });
    });

    Object.keys(transcript.speakers).forEach((key) => {
      let speaker = transcript.speakers[key];
      speaker.user_id =
        typeof speaker.user_id === "string"
          ? parseFloat(speaker.user_id)
          : speaker.user_id;
    });

    return transcript;
  } catch (error: any) {
    throw new Error(`Error converting props to number: ${error}`);
  }
}

export const chooseColor = (
  confidence: number,
  initial: string | null | undefined,
  text: string,
  suggested: string | null | undefined,
  suggestionInteraction: boolean,
  auto_apply?: boolean | null | undefined
) => {
  var colors = {
    mediumConfidence: "confidence-medium",
    lowConfidence: "confidence-low",
    lowestConfidence: "confidence-very-low",
    edited: "confidence-edited",
    suggested: "confidence-suggested",
    transparent: "",
  };

  var isEdited = false;

  if (initial && text !== initial) {
    isEdited = true;
  }
  var hasSuggestion = false;

  if (suggested && !suggestionInteraction) {
    hasSuggestion = true;
  }

  if (auto_apply == true) {
    hasSuggestion = false;
  }

  if (isEdited) {
    return colors.edited;
  } else if (hasSuggestion) {
    return colors.suggested;
  } else if (confidence <= 0.5 && confidence > 0.3) {
    return colors.mediumConfidence;
  } else if (confidence <= 0.3 && confidence > 0.1) {
    return colors.lowConfidence;
  } else if (confidence <= 0.1) {
    return colors.lowestConfidence;
  } else {
    return colors.transparent;
  }
};

export const filterWordsWithNoText = (utterances: UtteranceModel[]) => {
  utterances.forEach((utterance) => {
    utterance.sentences = utterance.sentences.filter(
      (sentence) => sentence.text !== ""
    );
    utterance.sentences.forEach((sentence) => {
      sentence.words = sentence.words.filter((word) => word.text !== "");
    });
  });
  return utterances;
};

export function getSpansInSelection() {
  const selection = window.getSelection();

  if (!selection) return null;

  let anchorOffset = selection.anchorOffset;
  let focusOffset = selection.focusOffset;

  let startNode = selection.anchorNode;
  let endNode = selection.focusNode;

  let direction = "none";
  if (selection.anchorNode === selection.focusNode) {
    direction = selection.anchorOffset <= selection.focusOffset ? "ltr" : "rtl";
  } else {
    const position = selection.anchorNode!.compareDocumentPosition(
      selection.focusNode!
    );
    direction = position === Node.DOCUMENT_POSITION_FOLLOWING ? "ltr" : "rtl";
  }

  if (direction === "rtl") {
    if (startNode && startNode.nodeType !== Node.TEXT_NODE) {
      startNode = startNode.previousSibling!.firstChild;
      anchorOffset = startNode!.textContent!.length;
    }
  }

  if (startNode && startNode.nodeType === Node.TEXT_NODE) {
    startNode = startNode.parentNode;
  }

  if (endNode && endNode.nodeType !== Node.TEXT_NODE) {
    endNode = endNode.previousSibling!.firstChild;
    focusOffset = endNode!.parentNode!.textContent!.length;
  }

  if (endNode && endNode.nodeType === Node.TEXT_NODE) {
    endNode = endNode.parentNode;
  }

  if (
    !startNode ||
    !endNode ||
    startNode.nodeName !== "SPAN" ||
    endNode.nodeName !== "SPAN"
  ) {
    return null;
  }

  const spans = document.getElementsByTagName("span");

  const startIndex = Array.prototype.indexOf.call(spans, startNode);
  const endIndex = Array.prototype.indexOf.call(spans, endNode);

  const spansInSelection = Array.prototype.slice.call(
    spans,
    Math.min(startIndex, endIndex),
    Math.max(startIndex, endIndex) + 1
  );

  if (direction === "rtl") {
    let temp = anchorOffset;
    anchorOffset = focusOffset;
    focusOffset = temp;
  }

  return {
    spans: spansInSelection,
    anchorOffset: anchorOffset,
    focusOffset: focusOffset,
  };
}

export const updateSencenceTags = (
  utterances: UtteranceModel[],
  newTag: TagToRenderModel
) => {
  for (let i = 0; i < utterances.length; i++) {
    for (let j = 0; j < utterances[i].sentences.length; j++) {
      if (
        (utterances[i].sentences[j].start <= newTag.start &&
          utterances[i].sentences[j].end >= newTag.start) ||
        (utterances[i].sentences[j].start >= newTag.start &&
          utterances[i].sentences[j].end <= newTag.end) ||
        (utterances[i].sentences[j].start <= newTag.end &&
          utterances[i].sentences[j].end >= newTag.end)
      ) {
        utterances[i].sentences[j].tags =
          utterances[i].sentences[j].tags !== undefined
            ? [
                ...utterances[i].sentences[j].tags!,
                { name: newTag.name, color: newTag.color },
              ]
            : [{ name: newTag.name, color: newTag.color }];
        utterances[i].sentences[j].words.forEach((word) => {
          word.tags =
            word.tags !== undefined
              ? [...word.tags, { name: newTag.name, color: newTag.color }]
              : [{ name: newTag.name, color: newTag.color }];
        });
      }
    }
  }
  return utterances;
};

export const clearSencenceTags = (
  utterances: UtteranceModel[],
  tag: TagModel,
  taggedSentences: SentenceModel[]
) => {
  for (let u = 0; u < utterances.length; u++) {
    for (let s = 0; s < utterances[u].sentences.length; s++) {
      for (let t = 0; t < taggedSentences.length; t++) {
        if (taggedSentences[t].start == utterances[u].sentences[s].start) {
          utterances[u].sentences[s].tags = utterances[u].sentences[
            s
          ].tags?.filter((t) => t.name !== tag.name);
          utterances[u].sentences[s].words.forEach((word) => {
            word.tags = word.tags?.filter((t) => t.name !== tag.name);
          });
        }
      }
    }
  }
  return utterances;
};

export function hexToRGBA(hex: string, opacity: number) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const getTagFromSelection = (utterances: UtteranceModel[]) => {
  const selection = window.getSelection();

  if (!selection) return null;

  let startNode = selection.anchorNode;
  let endNode = selection.focusNode;

  let direction = "none";
  if (selection.anchorNode === selection.focusNode) {
    direction = selection.anchorOffset <= selection.focusOffset ? "ltr" : "rtl";
  } else {
    const position = selection.anchorNode!.compareDocumentPosition(
      selection.focusNode!
    );
    direction = position === Node.DOCUMENT_POSITION_FOLLOWING ? "ltr" : "rtl";
  }

  if (direction === "rtl") {
    if (startNode && startNode.nodeType !== Node.TEXT_NODE) {
      startNode = startNode.previousSibling!.firstChild;
    }
  }

  if (startNode && startNode.nodeType === Node.TEXT_NODE) {
    startNode = startNode.parentNode;
  }

  if (endNode && endNode.nodeType !== Node.TEXT_NODE) {
    endNode = endNode.previousSibling!.firstChild;
  }

  if (endNode && endNode.nodeType === Node.TEXT_NODE) {
    endNode = endNode.parentNode;
  }

  if (direction === "rtl") {
    let temp = startNode;
    startNode = endNode;
    endNode = temp;
  }

  if (
    !startNode ||
    !endNode ||
    startNode.nodeName !== "SPAN" ||
    endNode.nodeName !== "SPAN"
  ) {
    return null;
  }

  let startNodeStart: string | null = (startNode as Element).getAttribute(
    "data-start"
  );
  let endNodeEnd: string | null = (endNode as Element).getAttribute("data-end");
  let selectedSentences: SentenceModel[] = [];

  var sentenceBeforeSelection: SentenceModel = {
    start: 0,
    end: 0,
    text: "",
    words: [],
  } as SentenceModel;
  var sentenceAfterSelection: SentenceModel = {
    start: 0,
    end: 0,
    text: "",
    words: [],
  } as SentenceModel;

  if (startNodeStart && endNodeEnd) {
    let prevSentence: SentenceModel | null = null;
    let selectionStarted = false;
    for (let utterance of utterances) {
      for (let i = 0; i < utterance.sentences.length; i++) {
        let sentence = utterance.sentences[i];
        if (
          (sentence.start >= Number(startNodeStart) &&
            sentence.start <= Number(endNodeEnd)) ||
          (sentence.start <= Number(startNodeStart) &&
            sentence.end >= Number(endNodeEnd)) ||
          (sentence.start <= Number(startNodeStart) &&
            sentence.end >= Number(startNodeStart))
        ) {
          if (prevSentence) {
            sentenceBeforeSelection = prevSentence;
            selectionStarted = true;
            prevSentence = null;
          }
          if (
            utterance.sentences[i + 1] &&
            utterance.sentences[i + 1].start > Number(endNodeEnd)
          ) {
            sentenceAfterSelection = utterance.sentences[i + 1];
          }
          selectedSentences.push(sentence);
        }
        if (!selectionStarted) {
          prevSentence = sentence;
        }
      }
    }
  }

  if (selectedSentences.length === 0) {
    return null;
  }

  let commonTag = null;

  if (selectedSentences.length > 0 && selectedSentences[0].tags) {
    for (let tag of selectedSentences[0].tags) {
      if (
        selectedSentences.every(
          (sentence) =>
            sentence.tags && sentence.tags.some((t) => t.name === tag.name)
        )
      ) {
        commonTag = tag;
        break;
      }
    }
  }

  const { firstWord, lastWord } = getWordsFromSelection(selectedSentences);

  if (commonTag === null) {
    return null;
  }

  return {
    tag: commonTag,
    sentences: selectedSentences,
    sentenceAfterSelection,
    sentenceBeforeSelection,
    firstWord,
    lastWord,
  };
};

const getWordsFromSelection = (selectedSentences: SentenceModel[]) => {
  let firstWord = selectedSentences[0].words[0];
  let lastWord =
    selectedSentences[selectedSentences.length - 1].words[
      selectedSentences[selectedSentences.length - 1].words.length - 1
    ];

  if (firstWord.text == "") {
    outerLoop: for (let i = 0; i < selectedSentences.length; i++) {
      for (let j = 0; j < selectedSentences[i].words.length; j++) {
        if (selectedSentences[i].words[j].text !== "") {
          firstWord = selectedSentences[i].words[j];
          break outerLoop;
        }
      }
    }
  }

  if (lastWord.text == "") {
    outerLoop: for (let i = selectedSentences.length - 1; i >= 0; i--) {
      for (let j = selectedSentences[i].words.length - 1; j >= 0; j--) {
        if (selectedSentences[i].words[j].text !== "") {
          lastWord = selectedSentences[i].words[j];
          break outerLoop;
        }
      }
    }
  }

  return { firstWord, lastWord };
};

export const getSentenceBoundary = (
  spanStart: number,
  spanEnd: number,
  utterances: UtteranceModel[]
) => {
  let actualStart = spanStart;
  let actualEnd = spanEnd;
  for (let i = 0; i < utterances.length; i++) {
    for (let j = 0; j < utterances[i].sentences.length; j++) {
      if (
        spanStart >= utterances[i].sentences[j].start &&
        spanStart <= utterances[i].sentences[j].end
      ) {
        actualStart = utterances[i].sentences[j].words[0].start;
      }
      if (
        spanEnd <= utterances[i].sentences[j].end &&
        spanEnd >= utterances[i].sentences[j].start
      ) {
        actualEnd = utterances[i].sentences[j].end;
      }
    }
  }

  return { actualStart, actualEnd };
};

export const getAbsoluteOffsetTop = (element: any, stopAtElement: any) => {
  let offsetTop = 0;
  while (element && element !== stopAtElement) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
};

export const getOffsetTopRelativeToTranscriptEditor = (element: any) => {
  const transcriptEditor = document.querySelector(".transcript-editor");
  return getAbsoluteOffsetTop(element, transcriptEditor);
};

export const getTranscriptEditorOffsetTop = () => {
  const transcriptEditor = document.querySelector(".transcript-editor");
  return getAbsoluteOffsetTop(transcriptEditor, null);
};

export const findSpanStart = (
  utterances: UtteranceModel[],
  tagToRender: TagToRenderModel
) => {
  for (let i = 0; i < utterances.length; i++) {
    for (let j = 0; j < utterances[i].sentences.length; j++) {
      if (
        tagToRender &&
        utterances[i].sentences[j].start === tagToRender.start
      ) {
        if (
          utterances[i].sentences[j].words.every((word) => word.text === "")
        ) {
          if (utterances[i].sentences[j + 1]) {
            tagToRender.start = utterances[i].sentences[j + 1].start;
            continue;
          }
        }
        for (let w = 0; w < utterances[i].sentences[j].words.length; w++) {
          if (utterances[i].sentences[j].words[w].text !== "") {
            return document.querySelector(
              `span[data-start='${utterances[i].sentences[j].words[w].start}']`
            ) as HTMLElement;
          }
        }
      }
    }
  }
  return null;
};

export const findSpanEnd = (
  utterances: UtteranceModel[],
  tagToRender: TagToRenderModel
) => {
  for (let i = 0; i < utterances.length; i++) {
    for (let j = utterances[i].sentences.length - 1; j >= 0; j--) {
      if (tagToRender && utterances[i].sentences[j].end === tagToRender.end) {
        if (
          utterances[i].sentences[j].words.every((word) => word.text === "")
        ) {
          if (utterances[i].sentences[j - 1]) {
            tagToRender.end = utterances[i].sentences[j - 1].end;
            continue;
          }
        }
        for (let w = utterances[i].sentences[j].words.length - 1; w >= 0; w--) {
          if (utterances[i].sentences[j].words[w].text !== "") {
            return document.querySelector(
              `span[data-end='${utterances[i].sentences[j].words[w].end}']`
            ) as HTMLElement;
          }
        }
      }
    }
  }
  return null;
};

export const getTagsFromTranscript = (utterances: UtteranceModel[]) => {
  let tags: TaggedContentModel[] = [];

  for (let i = 0; i < utterances.length; i++) {
    let tagStarts: { [tagName: string]: number } = {};

    for (let j = 0; j < utterances[i].sentences.length; j++) {
      let sentence = utterances[i].sentences[j];
      if (sentence.tags) {
        for (let k = 0; k < sentence.tags.length; k++) {
          let tagName = sentence.tags[k].name;
          if (!hasPrevSentenceTag(utterances[i].sentences[j - 1], tagName!)) {
            tagStarts[tagName!] = sentence.words[0].start;
          }
          let existingTag = tags.find(
            (tag) => tag.name === tagName && tag.start === tagStarts[tagName!]
          );
          if (!existingTag) {
            tags.push({
              ...sentence.tags[k],
              start: tagStarts[tagName!],
              end: 0,
            } as TaggedContentModel);
          } else if (
            !hasNextSentenceTag(utterances[i].sentences[j + 1], tagName!)
          ) {
            existingTag.end = sentence.words[sentence.words.length - 1].end;
            delete tagStarts[tagName!];
          }
        }
      }
    }

    // If the utterance has only one sentence, update the end time of all tags in the tagStarts map
    if (utterances[i].sentences.length === 1) {
      let sentence = utterances[i].sentences[0];
      for (let tagName in tagStarts) {
        let existingTag = tags.find(
          (tag) => tag.name === tagName && tag.start === tagStarts[tagName]
        );
        if (existingTag) {
          existingTag.end = sentence.words[sentence.words.length - 1].end;
        }
      }
    }
  }

  return tags.length > 0 ? tags : undefined;
};

const hasPrevSentenceTag = (
  sentence: SentenceModel | undefined,
  tagName: string
) => {
  return (
    sentence &&
    sentence.tags &&
    sentence.tags.length > 0 &&
    sentence.tags.some((tag) => tag.name === tagName)
  );
};

const hasNextSentenceTag = (
  sentence: SentenceModel | undefined,
  tagName: string
) => {
  return (
    sentence &&
    sentence.tags &&
    sentence.tags.length > 0 &&
    sentence.tags.some((tag) => tag.name === tagName)
  );
};

export const clearWordsTags = (utterances: UtteranceModel[]): UtteranceModel[] => {
  const utterancesCopy = JSON.parse(JSON.stringify(utterances));
  for (let i = 0; i < utterancesCopy.length; i++) {
    for (let j = 0; j < utterancesCopy[i].sentences.length; j++) {
      utterancesCopy[i].sentences[j].words.forEach((word: WordModel) => {
        delete word.tags;
      });
    }
  }
  return utterancesCopy;
}

export const applyTagsToWords = (utterances: UtteranceModel[]) => {
  const utterancesCopy = JSON.parse(JSON.stringify(utterances));
  for (let i = 0; i < utterancesCopy.length; i++) {
    for (let j = 0; j < utterancesCopy[i].sentences.length; j++) {
      const sentence = utterancesCopy[i].sentences[j];
      sentence.words.forEach((word: WordModel) => {
        word.tags = sentence.tags?.map((tag: TagModel) => ({
          name: tag.name,
          color: tag.color,
        }));
      });
    }
  }
  return utterancesCopy;
};