/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, Suspense, lazy, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import * as Sentry from "@sentry/react";
import React from "react";
import { App } from "../App";
import { KTSVG, WithChildren, toAbsoluteUrl } from "../../_metronic/helpers";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils/DomHelpers.ts";
import TopBarProgress from "react-topbar-progress-indicator";
import { systemMode, useThemeMode } from "../../_metronic/partials/index.ts";
import { MasterLayout } from "../../_metronic/layout/MasterLayout.tsx";
import EventEmbedded from "../pages/event/EventEmbedded.tsx";
import { EmbeddedLayout } from "../../_metronic/layout/EmbeddedLayout.tsx";
import { useAuth } from "../modules/auth/index.ts";
import { EventListEmbedded } from "../pages/event/EventListEmbedded.tsx";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

Sentry.init({
  enabled: import.meta.env.MODE == "production",
  environment:
    import.meta.env.MODE == "production" ? import.meta.env.MODE : "development",
  dsn: "https://34979fed69aaf5b53e7a3fa2d96d0f2a@o1272037.ingest.sentry.io/4506265383272453",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const { BASE_URL } = import.meta.env;

const AppRoutes: FC = () => {
  const { originNotAllowed, waitingForParentOrigin } = useAuth();
  const EventPage = lazy(() => import("../pages/event/EventPage.tsx"));

  return (
    <BrowserRouter basename={BASE_URL}>
      <SentryRoutes>
        <Route element={<App />}>
          {window.self !== window.top && (
            <Route element={<EmbeddedLayout />}>
              <Route
                path="app/company/embedded/:company/events"
                element={
                  <ErrorBoundary>
                    <SuspensedView
                      additionalFallback={<GenericLoadingFallback />}
                    >
                      {waitingForParentOrigin ? (
                        <WaitingForOriginBoundary />
                      ) : (
                        <>
                          {originNotAllowed ? (
                            <OriginNotAllowedBoundary />
                          ) : (
                            <EventListEmbedded />
                          )}
                        </>
                      )}
                    </SuspensedView>
                  </ErrorBoundary>
                }
              />
              <Route
                path="app/event/embedded/:eventId"
                element={
                  <ErrorBoundary>
                    <SuspensedView additionalFallback={<EventFallback />}>
                      {waitingForParentOrigin ? (
                        <WaitingForOriginBoundary />
                      ) : (
                        <>
                          {originNotAllowed ? (
                            <OriginNotAllowedBoundary />
                          ) : (
                            <EventEmbedded />
                          )}
                        </>
                      )}
                    </SuspensedView>
                  </ErrorBoundary>
                }
              />
            </Route>
          )}
          {window.self == window.top && (
            <Route element={<MasterLayout />}>
              <Route
                path="app/:company/event/:eventId/:eventName"
                element={
                  <ErrorBoundary>
                    <SuspensedView additionalFallback={<EventFallback />}>
                      <EventPage />
                    </SuspensedView>
                  </ErrorBoundary>
                }
              />
              <Route
                path="app/event/:eventId"
                element={
                  <ErrorBoundary>
                    <SuspensedView additionalFallback={<EventFallback />}>
                      <EventPage />
                    </SuspensedView>
                  </ErrorBoundary>
                }
              />
              <Route path="error/*" element={<ErrorsPage />} />
              <Route path="/*" element={<PrivateRoutes />} />
            </Route>
          )}
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorType?: string;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Check error type or status code and set state accordingly
    if (error.response && error.response.status === 404) {
      this.setState({ errorType: "404" });
    } else {
      this.setState({ errorType: "generic" });
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.state.errorType === "404") {
        // Render custom 404 UI
        return (
          <div
            className="d-flex flex-column justify-content-end"
            style={{ height: "75vh" }}
          >
            <div className="w-100 d-flex justify-content-center">
              <div className="alert bg-light-danger d-flex flex-column flex-sm-row p-5 me-10 ms-10 mb-10">
                <KTSVG
                  path="media/icons/duotune/files/fil007.svg"
                  className="svg-icon-muted svg-icon-2hx me-4 text-danger"
                />
                <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
                  <h5 className="mb-1">Page Not Found!</h5>
                  <span>
                    The page you are looking for does not exist. Please check
                    the URL or go back to the homepage.
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        // Render generic error UI
        return (
          <div
            className="d-flex flex-column justify-content-end"
            style={{ height: "75vh" }}
          >
            <div className="w-100 d-flex justify-content-center">
              <div className="alert bg-light-danger d-flex flex-column flex-sm-row p-5 me-10 ms-10 mb-10">
                <KTSVG
                  path="media/icons/duotune/files/fil007.svg"
                  className="svg-icon-muted svg-icon-2hx me-4 text-danger"
                />
                <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
                  <h5 className="mb-1">Something went wrong!</h5>
                  <span>
                    We are sorry, there has been an error when retrieving data.
                    Please try again later.
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return this.props.children;
  }
}

interface SuspensedViewProps extends WithChildren {
  additionalFallback?: React.ReactNode;
}

export const SuspensedView: FC<SuspensedViewProps> = ({
  children,
  additionalFallback,
}) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return (
    <Suspense
      fallback={
        <>
          <TopBarProgress />
          {additionalFallback}
        </>
      }
    >
      {children}
    </Suspense>
  );
};

const EventFallback: React.FC = () => {
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  return (
    <div className="d-flex justify-content-center" style={{ height: "75vh" }}>
      <div className="d-flex flex-column justify-content-center">
        {calculatedMode === "dark" ? (
          <img
            className="w-75 align-self-center"
            src={toAbsoluteUrl("media/logos/stokkio/logo-white-bubble.svg")}
          />
        ) : (
          <img
            src={toAbsoluteUrl("media/logos/stokkio/logo-black-bubble.svg")}
          />
        )}
        <p className="font-weight-bold mt-4 align-self-center text-muted fs-5">
          Loading the event ...
        </p>
      </div>
    </div>
  );
};

const GenericLoadingFallback: React.FC = () => {
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  return (
    <div className="d-flex justify-content-center" style={{ height: "75vh" }}>
      <div className="d-flex flex-column justify-content-center">
        {calculatedMode === "dark" ? (
          <img
            className="w-75 align-self-center"
            src={toAbsoluteUrl("media/logos/stokkio/logo-white-bubble.svg")}
          />
        ) : (
          <img
            src={toAbsoluteUrl("media/logos/stokkio/logo-black-bubble.svg")}
          />
        )}
        <p className="font-weight-bold mt-4 align-self-center text-muted fs-5">
          Loading ...
        </p>
      </div>
    </div>
  );
};

const OriginNotAllowedBoundary: React.FC = () => {
  return (
    <div className="d-flex justify-content-center" style={{ height: "75vh" }}>
      <div className="d-flex flex-column justify-content-center">
        <img
          className="w-75 align-self-center"
          src={toAbsoluteUrl("media/logos/stokkio/logo-white-bubble.svg")}
        />
        <p className="font-weight-bold mt-4 align-self-center text-muted fs-5">
          This website is not allowed to display the content.
        </p>
      </div>
    </div>
  );
};

const WaitingForOriginBoundary: React.FC = () => {
  return (
    <div className="d-flex justify-content-center" style={{ height: "75vh" }}>
      <div className="d-flex flex-column justify-content-center">
        <img
          className="w-75 align-self-center"
          src={toAbsoluteUrl("media/logos/stokkio/logo-white-bubble.svg")}
        />
        <p className="font-weight-bold mt-4 align-self-center text-muted fs-5">
          Authorizing the website ...
        </p>
      </div>
    </div>
  );
};

export { AppRoutes };
