import clsx from "clsx";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu, ThemeModeSwitcher } from "../../../partials";
import { useLayout } from "../../core";
import { ProfilePicture } from "../../../partials/layout/header-menus/ProfilePicture";

const itemClass = "ms-1 ms-md-4";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px";
const userAvatarClass = "symbol-35px";
const btnIconClass = "fs-2";

const LoInRedirect = import.meta.env.VITE_APP_AUTH_REDIRECT;

const Navbar = () => {
  const { config } = useLayout();

  const redirectToLogIn = () => {
    window.location.href = LoInRedirect;
  };
  return (
    <div className="app-navbar flex-shrink-0">
      <div className={clsx("app-navbar-item", itemClass)}>
        {config.isPublic ? (
          <a href={LoInRedirect} className="btn btn-active-dark text-muted">
            Sign In
          </a>
        ) : (
          <ThemeModeSwitcher
            toggleBtnClass={clsx(
              `${
                config.isEventPage
                  ? "btn btn-icon btn-icon hover-custom-color btn-custom"
                  : "btn-active-light-primary btn-custom"
              }`
            )}
          />
        )}
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        {config.isPublic ? (
          <></>
        ) : (
          <div
            className={clsx("cursor-pointer symbol", userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
          >
            <ProfilePicture />
          </div>
        )}
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_header_menu_toggle"
          >
            <KTIcon iconName="text-align-left" className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
