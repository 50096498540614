import React, { FC, useEffect, useRef, useState } from "react";
import {
  AllSearchResults,
  SemanticSearchResults,
  TextSearchResult,
  LanguageModel,
  TranscriptModel,
  SemanticSearchResult,
} from "../../../core/_models";
import {
  applyHighlight,
  removeHighlights,
  scrollToResult,
  searchForPhrases,
} from "../../../core/_helpers";
import SemanticSearch from "./components/SemanticSearch";
import SearchResults from "./components/SearchResults";
import { Color } from "../../../../../../_metronic/partials/notification/Notification";
import Transcript from "./Transcript";
import { systemMode, useThemeMode } from "../../../../../../_metronic/partials";
import { SuspensedView } from "../../../../../routing/PrivateRoutes";
import HighlightSelect from "./components/HighlightSelect";
import SentimentPopover from "../../../../../../_metronic/partials/popover/HighlightPopover";
import HighlightPopover from "../../../../../../_metronic/partials/popover/HighlightPopover";

interface TranscriptWrapperProps {
  playerTime: React.MutableRefObject<number>;
  seekedTime: number;
  updateTags: boolean;
  onWordClick: (time: number) => void;
  createNotification: (color: Color, message: string) => void;
  onFailedToFetchTranscript: () => void;
  width: number;
  eventId: string;
  availableLanguages?: LanguageModel[];
  embedded?: boolean;
  hasTranscript?: boolean;
}

const TranscriptWrapper: FC<TranscriptWrapperProps> = ({
  playerTime,
  seekedTime,
  onWordClick,
  updateTags,
  width,
  eventId,
  embedded,
  createNotification,
  availableLanguages,
  hasTranscript,
  onFailedToFetchTranscript,
}) => {
  const { customBtnColor, customTextColor } = useThemeMode();
  const [transcript, setTranscript] = useState<TranscriptModel | undefined>(
    {} as TranscriptModel
  );
  const [hideSearch, setHideSearch] = useState<boolean>(false);
  const [shouldApplySentimentAnalysis, setShouldApplySentimentAnalysis] =
    useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<
    SemanticSearchResults | undefined
  >(undefined);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [allSearchResults, setAllSearchResults] = useState<AllSearchResults>(
    {} as AllSearchResults
  );
  const [searchHighlight, setSearchHighlight] = useState<boolean>(true);
  const [displaySearchResults, setDisplaySearchResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [wasSearched, setWasSearched] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [language, setLanguage] = useState<LanguageModel | undefined>(
    undefined
  );
  const [displayTags, setDisplayTags] = useState<boolean>(false);
  const didRecieveResultsRef = useRef(false);
  interface SemanticSearchMethods {
    getSemanticSearch: () => void;
  }

  type SemanticSearchRef = SemanticSearchMethods & React.Ref<unknown>;

  const semanticSearchRef = useRef<SemanticSearchRef | null>(null);
  const transcriptWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hasTranscript) {
      if (availableLanguages && availableLanguages.length > 0) {
        setLanguage(availableLanguages[0]);
      }
    }
  }, [hasTranscript]);

  useEffect(() => {
    if (
      language &&
      language.code &&
      wasSearched &&
      semanticSearchRef.current !== null
    ) {
      removeHighlights();
      setAllSearchResults({ results: [] });
      setCurrentIndex(0);
      semanticSearchRef.current.getSemanticSearch();
    }
  }, [language]);

  const applySentimentAnalysis = () => {
    if (shouldApplySentimentAnalysis == true) {
      setShouldApplySentimentAnalysis(false);
    } else {
      setShouldApplySentimentAnalysis(true);
    }
  };

  useEffect(() => {
    if (searchResults !== undefined) {
      setDisplaySearchResults(true);
      var allResults: (TextSearchResult | SemanticSearchResult)[] = [];
      if (availableLanguages && availableLanguages.length == 1) {
        allResults = searchInTranscript();
      } else if (availableLanguages && availableLanguages.length == 0) {
        allResults = searchInTranscript();
      } else if (
        availableLanguages &&
        availableLanguages.length > 1 &&
        language &&
        language.code == availableLanguages[0].code
      ) {
        allResults = searchInTranscript();
      }
      allResults.push(...searchResults.results);
      allResults.sort((a, b) => a.start - b.start);
      setAllSearchResults({ results: allResults });
    }
  }, [searchResults]);

  useEffect(() => {
    if (didRecieveResultsRef.current) {
      if (searchResults && searchResults.results.length > 0) {
        scrollToSearchResult(
          searchResults!.results[0].start,
          searchResults!.results[0].end
        );
      }
    }
  }, [allSearchResults]);

  const scrollToSearchResult = (resultStart: number, resultEnd: number) => {
    let allSearchResultsCopy = allSearchResults.results.slice();
    let currentIndexCopy = allSearchResultsCopy.findIndex(
      (result) => result.start == resultStart && result.end == resultEnd
    );
    setCurrentIndex(currentIndexCopy);
  };

  const searchInTranscript = () => {
    var searchQueryCopy = searchQuery.slice();

    let searchWords = searchQueryCopy
      .split(" ")
      .filter((string) => string !== "");

    let foundSpans = searchForPhrases(searchWords, false, transcript);
    if (foundSpans == null) {
      return [];
    }
    return foundSpans;
  };

  useEffect(() => {
    if (
      allSearchResults.results !== undefined &&
      allSearchResults.results.length > 0
    ) {
      if (searchHighlight && didRecieveResultsRef.current) {
        applyHighlight(allSearchResults.results[currentIndex]);
      }
      if (didRecieveResultsRef.current)
        scrollToResult(allSearchResults, currentIndex);
    }
  }, [allSearchResults]);

  useEffect(() => {
    if (
      allSearchResults.results !== undefined &&
      allSearchResults.results.length > 0
    ) {
      if (searchHighlight && didRecieveResultsRef.current) {
        applyHighlight(allSearchResults.results[currentIndex]);
      }
      if (didRecieveResultsRef.current)
        scrollToResult(allSearchResults, currentIndex);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (transcript && transcript.utterances)
      onWordClick(transcript?.utterances[0].start);
  }, [transcript]);

  useEffect(() => {
    if (!embedded || !transcriptWrapperRef || !transcriptWrapperRef.current) return;

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const transcriptWidth= transcriptWrapperRef.current!.getBoundingClientRect().width;
    if (transcriptWidth < 576) {
      setHideSearch(true);
    } else {
      setHideSearch(false);
    }
  };

  const [highlightTags, setHighlightTags] = useState<boolean>(false);

  return (
    <div
    ref={transcriptWrapperRef}
      className={`${
        width < 576
          ? "transcript-height-mobile"
          : "transcript-height card card custom"
      } ${embedded ? "rounded-0" : ""}`}
      style={{ height: embedded ? "calc(100vh - 100px)" : "" }}
    >
      {embedded ? (
        <></>
      ) : (
        <div className="card-header">
          {width < 576 ? (
            <></>
          ) : (
            <h1 className="card-title custom-title">Event transcript</h1>
          )}

          <div
            className={`card-toolbar d-flex align-items-center ${
              embedded ? "pt-3 ps-5" : ""
            }`}
          >
            {((transcript && transcript.sentiment_analysis) ||
              wasSearched ||
              (transcript && transcript.tagged_content)) && (
              <>
                <HighlightPopover
                  hasSentiment={
                    transcript && transcript.sentiment_analysis ? true : false
                  }
                  wasSearched={wasSearched}
                  hasTags={
                    transcript && transcript.tagged_content ? true : false
                  }
                  position="bottom"
                ></HighlightPopover>
                <HighlightSelect
                  onDisplayTags={setDisplayTags}
                  onHighlightSentiment={setShouldApplySentimentAnalysis}
                  onHighlightSearch={setSearchHighlight}
                  onHighlightTags={setHighlightTags}
                  wasSearched={
                    allSearchResults &&
                    allSearchResults.results &&
                    allSearchResults.results.length
                      ? true
                      : false
                  }
                  hasSentiment={
                    transcript && transcript.sentiment_analysis ? true : false
                  }
                  hasTags={
                    transcript && transcript.tagged_content ? true : false
                  }
                ></HighlightSelect>
              </>
            )}
          </div>
        </div>
      )}
      <div
        className={`card-body ${
          embedded ? "pt-1" : "pt-5"
        } card-body-height p-0`}
      >
        <div
          className={`d-flex ms-5 me-5 ${embedded ? "align-items-center" : ""}`}
        >
          {wasSearched && (
            <a
              className="btn btn-active-secondary btn-icon btn-rounded me-1"
              onClick={() => setDisplaySearchResults(!displaySearchResults)}
            >
              <i
                className={`ki-outline ${
                  width > 576
                    ? displaySearchResults
                      ? "ki-arrow-left"
                      : "ki-arrow-right"
                    : displaySearchResults
                    ? "ki-arrow-up"
                    : "ki-arrow-down"
                } fs-1`}
              ></i>
            </a>
          )}
          {hideSearch && embedded ? (
            <></>
          ) : (
            <SemanticSearch
              language={language}
              embedded={embedded}
              allSearchResults={allSearchResults}
              currentIndexChange={currentIndex}
              onChangeIndex={setCurrentIndex}
              onIsSearching={(isSearching) => {
                didRecieveResultsRef.current = !isSearching;
                setIsSearching(isSearching);
              }}
              createNotification={createNotification}
              eventId={eventId}
              onSetSearchQUery={(value) => {
                setSearchQuery(value);
                setWasSearched(true);
                setDisplaySearchResults(true);
              }}
              onResultsRecieved={setSearchResults}
              ref={semanticSearchRef}
            ></SemanticSearch>
          )}
          {((transcript && transcript.sentiment_analysis) ||
            wasSearched ||
            (transcript && transcript.tagged_content)) &&
            embedded && (
              <div
                className="d-flex w-100 ms-3 mb-1"
                style={{ height: "min-content" }}
              >
                <HighlightPopover
                  hasSentiment={
                    transcript && transcript.sentiment_analysis ? true : false
                  }
                  embedded={embedded}
                  wasSearched={wasSearched}
                  hasTags={
                    transcript && transcript.tagged_content ? true : false
                  }
                  position="bottom"
                ></HighlightPopover>
                <HighlightSelect
                  onDisplayTags={setDisplayTags}
                  onHighlightSentiment={setShouldApplySentimentAnalysis}
                  onHighlightSearch={setSearchHighlight}
                  onHighlightTags={setHighlightTags}
                  wasSearched={
                    allSearchResults &&
                    allSearchResults.results &&
                    allSearchResults.results.length
                      ? true
                      : false
                  }
                  hasSentiment={
                    transcript && transcript.sentiment_analysis ? true : false
                  }
                  hasTags={
                    transcript && transcript.tagged_content ? true : false
                  }
                ></HighlightSelect>
              </div>
            )}
        </div>

        <div
          className={`transcript-content-wrapper w-100 d-flex ${
            width > 576 ? "flex-row" : "flex-column"
          } ${embedded ? "content-box-height-embedded" : "content-box-height"}`}
        >
          {wasSearched == true && (
            <>
              <SearchResults
                embedded={embedded}
                isSearching={isSearching}
                width={width}
                displaySearchResults={displaySearchResults}
                searchResults={searchResults}
                searchQuery={searchQuery}
                onScrollToResult={scrollToSearchResult}
              ></SearchResults>
            </>
          )}
          <div
            className={`d-flex flex-column h-100 ${
              searchResults == undefined
                ? "w-100"
                : displaySearchResults && width > 576
                ? "width-70"
                : ""
            }`}
          >
            <div className="d-flex ms-5 language-bar">
              {availableLanguages &&
                availableLanguages.length > 1 &&
                language &&
                availableLanguages.map((availableLanguage) => (
                  <a
                    key={availableLanguage.code}
                    onClick={() => setLanguage(availableLanguage)}
                    className={`btn btn-sm btn-ribbon-lang ${
                      language.code == availableLanguage.code
                        ? customBtnColor
                          ? "btn-iframe-custom " + customTextColor
                          : "btn-ribbon-lang-active btn-primary"
                        : customBtnColor
                        ? "btn-iframe-custom-darker " + customTextColor
                        : "btn-light-primary btn-active-light-primary"
                    }`}
                    style={
                      customBtnColor
                        ? { "--custom-btn-color": customBtnColor }
                        : {}
                    }
                  >
                    {availableLanguage.name}
                  </a>
                ))}
            </div>
            <SuspensedView
              additionalFallback={
                <TranscriptFallback
                  width={width}
                  embedded={embedded}
                ></TranscriptFallback>
              }
            >
              <Transcript
                embedded={embedded}
                availableLanguages={availableLanguages}
                onFailedToFetchTranscript={onFailedToFetchTranscript}
                highlightTags={highlightTags}
                displaySearchResults={displaySearchResults}
                updateTags={updateTags}
                transcriptLanguage={language}
                displayTags={displayTags}
                onTranscript={(transcript) => setTranscript(transcript)}
                onWordClick={onWordClick}
                eventId={eventId}
                hasTranslations={
                  language == undefined || language == null
                    ? false
                    : language.code == null
                    ? false
                    : true
                }
                seekedTime={seekedTime}
                playerTime={playerTime}
                allSearchResults={allSearchResults}
                currentIndex={currentIndex}
                shouldApplyHighlight={searchHighlight}
                shouldApplySentimentAnalysis={shouldApplySentimentAnalysis}
              ></Transcript>
            </SuspensedView>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranscriptWrapper;

export interface TranscriptFallbackProps {
  width: number;
  embedded?: boolean;
}

export const TranscriptFallback: React.FC<TranscriptFallbackProps> = ({
  width,
  embedded,
}) => {
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  return (
    <div
      className={`scrollable ${
        embedded ? "" : "w-100 text-box-height"
      } d-flex flex-column align-center justify-content-center ${
        embedded ? "rounded-0" : ""
      }`}
      style={{ height: embedded ? "100%" : "", width: "calc(100vw - 5px)" }}
    >
      <p className="font-weight-bold mt-4 align-self-center text-muted fs-5">
        <i className="ki-outline ki-document fs-1 align-self-center mb-0 me-2"></i>
        Loading the transcript...
      </p>
      <span className="spinner-border spinner-border-large align-self-center text-muted"></span>
    </div>
  );
};
