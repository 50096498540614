import { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import {
  AllSearchResults,
  EventWordModel,
  LanguageModel,
  TaggedContentModel,
  TranscriptModel,
  WordChapterModel,
} from "../../../core/_models";
import Chapter from "./components/Chapter";
import { useQuery } from "react-query";
import {
  SentenceModel,
  TagToRenderModel,
  UtteranceModel,
  WordModel,
} from "../../../../transcript/core/_models";
import {
  createTagColorsCSS,
  getEventTranscriptOffsetTop,
  getOffsetTopRelativeToEventTranscript,
  groupWordsByChapter,
} from "../../../core/_helpers";
import { getTranscriptWithSentiment } from "../../../core/_requests";
import Tags from "../../../../transcript/components/Tags";
import {
  findSpanEnd,
  findSpanStart,
} from "../../../../transcript/core/_helpers";
import FloatingControls from "../../../../transcript/components/FloatingControls";
import { useAuth } from "../../../../../modules/auth";

const getTranscript = async (
  eventId: string,
  hasTranslations: boolean,
  requestedLanguage?: LanguageModel,
  key?: string
): Promise<{
  transcript: TranscriptModel;
  words: WordModel[];
  wordChapters: WordChapterModel[][];
} | null> => {
  try {
    let response = await getTranscriptWithSentiment(
      eventId,
      hasTranslations,
      requestedLanguage,
      key
    );

    const transcript = response.data as TranscriptModel;
    let words: WordModel[] = [];
    transcript.utterances.forEach((utterance: UtteranceModel, indexU) => {
      utterance.sentences.forEach((sentence: SentenceModel, index) => {
        sentence.words.forEach((word: EventWordModel) => {
          if (sentence.sentiment) {
            word.sentiment = sentence.sentiment;
          }
          words.push({
            ...word,
            text: word.text,
            tags: sentence.tags ? sentence.tags : word.tags ? word.tags : [],
          });
        });
      });
    });

    if (transcript.tags) {
      createTagColorsCSS(transcript.tags);
    }

    const wordChapters = groupWordsByChapter(
      transcript.utterances,
      words,
      transcript.chapters
    );

    return { transcript, words, wordChapters };
  } catch {
    return null;
  }
};

interface TranscriptProps {
  onTranscript: (transcript: TranscriptModel) => void;
  onWordClick: (time: number) => void;
  onFailedToFetchTranscript: () => void;
  seekedTime: number;
  eventId: string;
  playerTime: MutableRefObject<number>;
  allSearchResults: AllSearchResults;
  currentIndex: number;
  shouldApplyHighlight: boolean;
  shouldApplySentimentAnalysis: boolean;
  transcriptLanguage: LanguageModel | undefined;
  hasTranslations: boolean;
  availableLanguages?: LanguageModel[];
  displayTags: boolean;
  updateTags: boolean;
  displaySearchResults: boolean;
  highlightTags: boolean;
  embedded?: boolean;
}

const Transcript: FC<TranscriptProps> = ({
  shouldApplySentimentAnalysis,
  displaySearchResults,
  shouldApplyHighlight,
  onWordClick,
  eventId,
  seekedTime,
  playerTime,
  hasTranslations,
  allSearchResults,
  currentIndex,
  transcriptLanguage,
  onTranscript,
  displayTags,
  updateTags,
  highlightTags,
  onFailedToFetchTranscript,
  availableLanguages,
  embedded,
}) => {
  const { key } = useAuth();
  const [transcript, setTranscript] = useState<TranscriptModel | undefined>(
    {} as TranscriptModel
  );
  const [words, setWords] = useState<WordModel[]>([]);
  const [language, setLanguage] = useState<string>("");
  const [wordChapters, setWordChapters] = useState<WordChapterModel[][]>([]);
  const [showSuspense, setShowSuspense] = useState<boolean>(true);

  const {
    data: fetchedData,
    refetch,
    isError,
    error,
  } = useQuery(
    ["transcript", transcriptLanguage],
    () => getTranscript(eventId!, hasTranslations, transcriptLanguage, key),
    {
      suspense: showSuspense,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (fetchedData) {
      setTranscript(fetchedData.transcript);
      setWords(fetchedData.words);
      setWordChapters(fetchedData.wordChapters);
      onTranscript(fetchedData.transcript);
      setTagsBarOpened(
        fetchedData.transcript.tagged_content !== undefined ? true : false
      );
      setTags(
        fetchedData.transcript.tagged_content
          ? (fetchedData.transcript.tagged_content!.map(
              (taged: TaggedContentModel) => {
                return {
                  start: taged.start,
                  end: taged.end,
                  name: taged.name,
                  color: taged.color,
                  topOffest: 0,
                  height: 0,
                };
              }
            ) as TagToRenderModel[])
          : null
      );
    } else {
      setTranscript(undefined);
      onFailedToFetchTranscript();
    }
  }, [fetchedData]);

  const [tags, setTags] = useState<TagToRenderModel[] | null>(null);
  const [tagsBarOpened, setTagsBarOpened] = useState<boolean>(true);

  const recalculateTagParams = () => {
    if (tagsRef.current) {
      setTimeout(() => {
        let tagsCopy = [...tagsRef.current!];
        for (let i = tagsCopy.length - 1; i >= 0; i--) {
          let spanStart = document.querySelector(
            `span[data-start='${tagsCopy[i].start}']`
          ) as HTMLElement;
          let spanEnd = document.querySelector(
            `span[data-end='${tagsCopy[i].end}']`
          ) as HTMLElement;
          if (!spanStart) {
            let newSpanStart = findSpanStart(
              transcript!.utterances,
              tagsCopy[i]
            );
            if (newSpanStart) {
              spanStart = newSpanStart;
            }
          }

          if (!spanEnd) {
            let newSpanEnd = findSpanEnd(transcript!.utterances, tagsCopy[i]);
            if (newSpanEnd) {
              spanEnd = newSpanEnd;
            }
          }

          if (!spanStart || !spanEnd) {
            continue;
          }

          const offsetTopRelative =
            getOffsetTopRelativeToEventTranscript(spanStart) - 17;
          const transcriptEditorOffsetTop = getEventTranscriptOffsetTop();
          const offsetTop = offsetTopRelative - transcriptEditorOffsetTop;
          const height =
            spanEnd.offsetTop + spanEnd.offsetHeight - spanStart.offsetTop;
          tagsCopy[i].topOffest = offsetTop;
          tagsCopy[i].height = height;
          tagsCopy[i].start = parseInt(spanStart.getAttribute("data-start")!);
          tagsCopy[i].end = parseInt(spanEnd.getAttribute("data-end")!);
        }
        setTags(tagsCopy);
      }, 100);
    }
  };

  const tagsRef = useRef(tags);

  useEffect(() => {
    tagsRef.current = tags;
  }, [tags]);

  function useDebouncedResize(callback: () => void, delay: number) {
    const timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
      const handleResize = () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          callback();
        }, delay);
      };

      window.addEventListener("resize", handleResize);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  }

  useEffect(() => {
    if (
      updateTags ||
      (transcript && tagsBarOpened && transcript.tagged_content)
    )
      recalculateTagParams();
  }, [transcript, tagsBarOpened, updateTags, displaySearchResults]);

  useDebouncedResize(recalculateTagParams, 100);

  return (
    <div
      id="srollable-transcript"
      className={`${embedded ? "scrollable-embedded" : "scrollable"} ${
        embedded
          ? availableLanguages && availableLanguages.length > 1
            ? "h-100"
            : "h-100"
          : transcript == undefined
          ? "text-box-height"
          : availableLanguages && availableLanguages.length > 1
          ? "text-box-height-translations"
          : "text-box-height"
      } ${embedded ? "rounded-0" : "mb-5"} p-5`}
    >
      {transcript == undefined ? (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <div className="d-flex flex-column mw-50 align-items-center">
            <i className="ki-outline ki-information-4 icon-lg"></i>
            <h1 className="fw-light text-center">
              Transcript for this event is not available.
            </h1>
          </div>
        </div>
      ) : (
        <div className="d-flex">
          <div className="w-100">
            {transcript!.utterances &&
              transcript!.utterances.length > 0 &&
              wordChapters.length > 0 &&
              transcript!.utterances.map((utterance, index) => (
                <div
                  key={utterance.start}
                  className={`event-utterance ${index !== 0 ? "mt-10" : ""}`}
                >
                  <h1>{transcript!.speakers[utterance.speaker].name}</h1>
                  {wordChapters.map(
                    (wordChapter: WordChapterModel[], index) => {
                      return (
                        <div key={index}>
                          {utterance.end == wordChapter[0].utterance!.end && (
                            <div className={`${index == 0 ? "" : "mt-4"}`}>
                              <Chapter
                                highlightTags={highlightTags}
                                onWordClick={onWordClick}
                                seekedTime={seekedTime}
                                timerTime={playerTime}
                                results={allSearchResults.results}
                                currentIndex={currentIndex}
                                shouldApplyHighlight={shouldApplyHighlight}
                                chapterStart={wordChapter[0].word.start}
                                chapterEnd={
                                  wordChapter[wordChapter.length - 1].word.end
                                }
                                shouldApplySentimentAnalysis={
                                  shouldApplySentimentAnalysis
                                }
                                chapterWords={wordChapter.map((wordChapter) => {
                                  return wordChapter.word;
                                })}
                              ></Chapter>
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              ))}
          </div>
          <Tags
            tags={tags}
            utterances={transcript.utterances}
            isToggled={displayTags}
          ></Tags>
          <FloatingControls
            hasTags={false}
            tagsBar={tagsBarOpened}
            closeTagsBar={() => {
              setTagsBarOpened(false);
            }}
            openTagsBar={() => {
              setTagsBarOpened(true);
            }}
          ></FloatingControls>
        </div>
      )}
    </div>
  );
};

export default Transcript;
