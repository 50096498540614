import { FC, useEffect, useRef } from "react";
import { useThemeMode } from "../../../../_metronic/partials";
import { getRootUrl, toAbsoluteUrl } from "../../../../_metronic/helpers";

const envMode = import.meta.env.MODE;

interface WidgetHolderProps {
  company?: string;
  color: string;
  height: number;
  theme: string;
  setTheme: (theme: string) => void;
}

const WidgetHolder: FC<WidgetHolderProps> = ({ company, theme, color, height, setTheme }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const prevDeps = useRef({ theme, color, height });
  const { mode } = useThemeMode();

  useEffect(() => {
    if(!iframeRef.current) return;

    if (prevDeps.current.theme !== theme) {
      iframeRef.current.contentWindow!.postMessage(
        { type: "stokkio-theme-change", theme },
        envMode === "development" ? "*" : "https://app.stokk.io"
      );
      prevDeps.current.theme = theme;
    }
    
    if (prevDeps.current.color !== color) {
      iframeRef.current.contentWindow!.postMessage(
        { type: "stokkio-button-change", color },
        envMode === "development" ? "*" : "https://app.stokk.io"
      );
      prevDeps.current.color = color;
    }

  }, [theme, color]);
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${getRootUrl()}/iframe-integration-script.js`;

    script.type = 'module';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="w-100 h-100">
      <iframe
        ref={iframeRef}
        id="stokkio-event-iframe"
        src={`${envMode === "development" ? "http://localhost:5173" : "https://app.stokk.io"}/app/company/embedded/${company}/events`}
        width="100%"
        height="100%"
        data-theme={theme}
        data-button-color={color}
        className="border-0"
        style={{ userSelect: "none"}}
      ></iframe>
    </div>
  );
};

export default WidgetHolder;
