import { FC, useEffect, useRef, useState } from "react";
import { EventSignUp } from "../../../../_metronic/partials/modals/event/EventSignUp";
import { Color } from "../../../../_metronic/partials/notification/Notification";
import { EventStatus, InvestorDetailsModel } from "../core/_models";
import { EditInvestorDetails } from "../../../../_metronic/partials/modals/event/EditInvestorDetails";
import { calculateCountdown, updateHeaderTitle } from "../core/_helpers";
import { UserModel } from "../../../modules/auth";
import { EventEmailModal } from "../../../../_metronic/partials/modals/event/EventEmailModal";
import { leaveEvent } from "../core/_requests";
import LoginModal from "../../../../_metronic/partials/modals/event/LoginModal";
import RegisterModal from "../../../../_metronic/partials/modals/event/RegisterModal";
import { useThemeMode } from "../../../../_metronic/partials";

const loginRedirect = import.meta.env.VITE_APP_AUTH_REDIRECT_LOGIN;
const signUpRedirect = import.meta.env.VITE_APP_AUTH_REDIRECT_SIGN_UP;

interface EventScheduledProps {
  eventId: string;
  startDate: string;
  eventStatus: EventStatus;
  title: string;
  userParticipating: boolean;
  author: string;
  initialCountdown: string;
  triggerSignUpModal: boolean;
  currentUser?: UserModel;
  externalJoinLink?: string;
  streamedOnStokkIo?: boolean;
  embedded?: boolean;
  changeTab?: (tab: string) => void;
  handleUserParticipating: (isUserParticipating: boolean) => void;
  createNotification: (color: Color, message: string) => void;
  postQuestion: (shouldPostQuestion: boolean) => void;
  onRefreshEvent: () => void;
}

const EventScheduled: FC<EventScheduledProps> = ({
  eventId,
  eventStatus,
  startDate,
  title,
  author,
  userParticipating,
  createNotification,
  triggerSignUpModal,
  currentUser,
  initialCountdown,
  handleUserParticipating,
  postQuestion,
  onRefreshEvent,
  externalJoinLink,
  streamedOnStokkIo,
  embedded,
  changeTab
}) => {
  const { customBtnColor, customTextColor } = useThemeMode();
  const bannerObserver = useRef<HTMLDivElement | null>(null);
  const [isUserParticipating, setIsUserParticipating] =
    useState(userParticipating);
  const [showSignUpEventModal, setShowSignUpEventModal] = useState(false);
  const [showEventModalEmail, setShowEventModalEmail] = useState(false);
  const [showEditDetailsModal, setShowEditDetailsModal] = useState(false);
  const [countdown, setCountdown] = useState<{
    countDown: string;
    startRefreshing: boolean;
  }>({ countDown: "", startRefreshing: false });
  const [startRefreshing, setStartRefreshing] = useState(false);
  const [displayExternalJoinLink, setDisplayExternalJoinLink] = useState(false);
  const [investorDetails, setInvestorDetails] = useState<InvestorDetailsModel>(
    {} as InvestorDetailsModel
  );
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterEventModal, setShowRegisterEventModal] = useState(false);
  const [emailForModal, setEmailForModal] = useState("");
  const countdownRef = useRef(countdown);

  useEffect(() => {
    if (triggerSignUpModal) {
      setShowSignUpEventModal(true);
    }
  }, [triggerSignUpModal]);

  useEffect(() => {
    countdownRef.current = countdown;
  }, [countdown]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countdownRef.current.startRefreshing == true) {
        setStartRefreshing(true);
        if (streamedOnStokkIo === false) {
          setDisplayExternalJoinLink(true);
        }
      }
      let count = calculateCountdown(startDate);
      setCountdown({
        countDown: count.countdown,
        startRefreshing: count.startRefreshing,
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startDate]);

  useEffect(() => {
    if (startRefreshing && streamedOnStokkIo !== false) {
      onRefreshEvent();
      const intervalId = setInterval(() => {
        onRefreshEvent();
      }, 30000);
      return () => clearInterval(intervalId);
    }
  }, [startRefreshing]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const action = queryParameters.get("action");

    if (action === "sign_up" && !isUserParticipating) {
      participate();
    }

    queryParameters.delete("action");
    const paramString = queryParameters.toString();
    window.history.replaceState({}, "", paramString ? "?" + paramString : "");
  }, []);

  const participate = () => {
    if (!currentUser) {
      setShowEventModalEmail(true);
      postQuestion(false);
    } else {
      postQuestion(false);
      setShowSignUpEventModal(true);
    }
  };

  const editDetails = () => {
    setShowEditDetailsModal(true);
  };

  const handleSignUpSubmited = (
    wasSuccessful: boolean,
    investorDetails: InvestorDetailsModel,
    message: string
  ) => {
    if (wasSuccessful) {
      setIsUserParticipating(true);
      handleUserParticipating(true);
      setInvestorDetails(investorDetails);
      createNotification("success", "You have been signed up for this event!");
      setShowSignUpEventModal(false);
    } else {
      handleUserParticipating(false);
      createNotification(
        "error",
        "Something went wrong durring sign up. Please try again."
      );
    }
  };

  const handleEditDetailsSaved = (
    wasSuccessful: boolean,
    investorDetails: InvestorDetailsModel,
    message: string
  ) => {
    if (wasSuccessful) {
      setInvestorDetails(investorDetails);
      createNotification("success", "Investor details saved!");
      setShowSignUpEventModal(false);
    } else {
      createNotification(
        "error",
        "Something went wrong durring saving. Please try again."
      );
    }
  };

  const handleCloseSignUpEventModal = () => setShowSignUpEventModal(false);

  const handleCloseEventModalEmail = () => setShowEventModalEmail(false);

  const handleCloseEditDetailsModal = () => setShowEditDetailsModal(false);

  const leave = async () => {
    var response = await leaveEvent(parseInt(eventId));

    if (response.status == 200) {
      setIsUserParticipating(false);
    } else {
      createNotification(
        "info",
        "Something went wrong. Try refreshing the page."
      );
    }
  };

  useEffect(() => {
    handleUserParticipating(isUserParticipating);
  }, [isUserParticipating]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          updateHeaderTitle(false);
        } else {
          updateHeaderTitle(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (bannerObserver.current) {
      observer.observe(bannerObserver.current);
    }

    return () => {
      if (bannerObserver.current) {
        observer.unobserve(bannerObserver.current);
      }
    };
  }, []);

  const askQuestion = () => {
    if(embedded){
      changeTab!("Questions");
    }
    var textArea = document.getElementById("questionTextArea");
    if (textArea) {
      textArea.focus();
      window.scrollBy({ top: 160, behavior: "smooth" });
    }
  };

  useEffect(() => {
    var appContainer = document.getElementById("kt_app_content_container");
    if (appContainer) {
      appContainer.classList.add("ps-0");
      appContainer.classList.add("pe-0");
      appContainer.classList.remove("app-container");
    }
  }, []);

  return (
    <div
      className={`${embedded ? "h-100" : ""}`}
      style={{ marginTop: embedded ? "32px" : "0px", overflow: "hidden" }}
    >
      <div
        className="event-banner pb-10"
        style={{ height: embedded ? "calc(100vh - 60px)" : "" }}
      >
        {isUserParticipating && externalJoinLink == undefined ? (
          <div className="align-self-center signed-up-badge">
            <span className="badge badge-success fs-4 fw-light mb-1 mt-1 mb-10">
              <i className="ki-outline ki-check-square text-white fs-1 me-2"></i>
              You're signed up for this event
            </span>
          </div>
        ) : (
          <div className="my-5"></div>
        )}

        <h1 className={`${embedded ? "display-4" : "display-2"} text-white`} ref={bannerObserver}>
          {title}
        </h1>
        <h3 className="fw-bold text-danger mb-4 fs-5">{startDate}</h3>
        {eventStatus === "ARCHIVED" ? (
          <></>
        ) : (
          <>
            <div className="separator border-secondary my-3 w-50"></div>
            {!displayExternalJoinLink ? (
              <>
                <h1 className="mb-1 text-white">Event starts in:</h1>
                <h3 className="display-5 text-white countdown mb-0">
                  {countdown.countDown == ""
                    ? initialCountdown
                    : countdown.countDown}
                </h3>
                {externalJoinLink && (
                  <a
                    className="btn btn-success mt-2 mb-2 d-flex"
                    href={externalJoinLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Go to stream{" "}
                    <i className="ki-outline ki-exit-right-corner  ms-1 fw-normal fs-3"></i>
                  </a>
                )}
              </>
            ) : (
              <>
                <h3 className="mb-2 text-white">
                  This event is not streamed on Stokk.io.
                </h3>
                {externalJoinLink && (
                  <a
                    className="btn btn-success mb-2 d-flex"
                    href={externalJoinLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Go to stream{" "}
                    <i className="ki-outline ki-exit-right-corner  ms-1 fw-normal fs-3"></i>
                  </a>
                )}
              </>
            )}
            <div className="separator border-secondary my-2 w-50"></div>
          </>
        )}
        {eventStatus === "ARCHIVED" ? (
          <>
            {!isUserParticipating ? (
              <>
                <h2 className=" mt-5 text-white">
                  This event has ended, please sign up below to access the
                  archived event.
                </h2>
                <a
                  onClick={participate}
                  className={`btn ${
                    customBtnColor
                      ? "btn-iframe-custom " + customTextColor
                      : "btn-primary hover-scale"
                  } fs-4 mt-2 ps-4 d-flex flex-center`}
                  style={
                    customBtnColor
                      ? { "--custom-btn-color": customBtnColor }
                      : {}
                  }
                >
                  <span
                    className={`ki-outline ki-plus-circle ${
                      customBtnColor ? customTextColor : "text-white"
                    } fs-2 me-2`}
                  ></span>
                  Sign up for event
                </a>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {!isUserParticipating ? (
              <>
                <h2 className=" mt-5 text-white">
                  Sign up below to be notified when the event starts.
                </h2>
                <a
                  onClick={participate}
                  className={`btn ${
                    customBtnColor
                      ? "btn-iframe-custom " + customTextColor
                      : "btn-primary hover-scale"
                  } fs-4 mt-2 ps-4 d-flex flex-center`}
                  style={
                    customBtnColor
                      ? { "--custom-btn-color": customBtnColor }
                      : {}
                  }
                >
                  <span className={`ki-outline ki-plus-circle ${customTextColor ? customTextColor : "text-white"} fs-2 me-2`}></span>
                  Sign up for event
                </a>
              </>
            ) : (
              <>
                {!displayExternalJoinLink ? (
                  <>
                    <h2 className="mt-7 text-white ">
                      You'll be notified when the event starts.
                    </h2>
                    <div className="btn-group mt-1 ">
                      <button
                        type="button"
                        className={`btn ${
                          customBtnColor
                            ? "btn-iframe-custom " + customTextColor
                            : "btn-primary"
                        } fs-4`}
                        style={
                          customBtnColor
                            ? { "--custom-btn-color": customBtnColor }
                            : {}
                        }
                        onClick={askQuestion}
                      >
                        Ask a question
                      </button>
                      <button
                        type="button"
                        className={`btn ${
                          customBtnColor
                            ? "btn-iframe-custom " + customTextColor
                            : "btn-primary"
                        } dropdown-toggle dropdown-toggle-split`}
                        style={
                          customBtnColor
                            ? { "--custom-btn-color": customBtnColor }
                            : {}
                        }
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu fs-5">
                        <li>
                          <a
                            className="dropdown-item d-flex flex-start"
                            href="#"
                            onClick={editDetails}
                          >
                            <i className="ki-outline ki-notepad-edit  me-2 fs-5 mt-1"></i>
                            Edit investment data
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item d-flex flex-start hover-danger"
                            href="#"
                            onClick={leave}
                          >
                            <i className="ki-outline ki-cross-circle  me-2 fs-5 pt-1"></i>
                            Leave the event
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}

        <EventSignUp
          show={showSignUpEventModal}
          eventId={eventId}
          handleClose={handleCloseSignUpEventModal}
          companyTitle={author}
          handleSubmited={handleSignUpSubmited}
        ></EventSignUp>
        <EventEmailModal
          handleLogin={(email) => {
            setShowLoginModal(true);
            setEmailForModal(email);
          }}
          embedded={embedded}
          handleSignup={(email) => {
            setShowRegisterEventModal(true);
            setEmailForModal(email);
          }}
          show={showEventModalEmail}
          login={loginRedirect}
          onCreateNotification={createNotification}
          signup={signUpRedirect}
          handleClose={handleCloseEventModalEmail}
          handleSubmited={handleCloseEventModalEmail}
        ></EventEmailModal>
        <LoginModal
          embedded={embedded}
          show={showLoginModal}
          email={emailForModal}
          handleClose={() => setShowLoginModal(false)}
          createNewNotification={createNotification}
        ></LoginModal>
        <RegisterModal
          embedded={embedded}
          openLoginModal={() => setShowLoginModal(true)}
          show={showRegisterEventModal}
          email={emailForModal}
          createNewNotification={createNotification}
          handleClose={() => setShowRegisterEventModal(false)}
        ></RegisterModal>
        <EditInvestorDetails
          show={showEditDetailsModal}
          eventId={eventId}
          handleClose={handleCloseEditDetailsModal}
          companyTitle={author}
          handleSaved={handleEditDetailsSaved}
          investorDetails={investorDetails}
        ></EditInvestorDetails>
      </div>
    </div>
  );
};

export default EventScheduled;
