import { FC, useEffect, useRef, useState } from "react";
import { registerIVSTech } from "amazon-ivs-player";
import videojs from "video.js";
import { KTSVG } from "../../../../_metronic/helpers";
import "video.js/dist/video-js.css";
import { isMobile } from "../core/_helpers";

interface EventLiveProps {
  eventEnd: string;
  playbackUrl?: string;
  embedded?: boolean;
  tab?: string;
}

declare global {
  interface Window {
    IVSPlayer: any;
  }
}

const EventLive: FC<EventLiveProps> = ({ eventEnd, playbackUrl, embedded, tab }) => {
  const questionsLinkRef = useRef<HTMLDivElement | null>(null);
  const [playerReady, setPlayerReady] = useState(true);
  const playerRef = useRef<videojs.Player | null>(null);
  const videoRef = useRef<HTMLDivElement | null>(null);
  const [shoudlDisplayVideo, setShouldDisplayVideo] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [didEventStart, setDidEventStart] = useState(false);
  const [loadingPlayer, setLoadingPlayer] = useState(true);
  const [didEventEnd, setDidEventEnd] = useState(false);

  const eventTitle = document.getElementById("header-event-title");
  if (eventTitle) {
    eventTitle.classList.add("visible");
  }

  useEffect(() => {
    let intervalId: number | null = null; 
    try {
      if (playbackUrl) {
        if (!playerRef.current) {
          let intervalId: number;

          const checkUrl = async () => {
            try {
              const response = await fetch(playbackUrl);
              if (response.ok) {
                clearInterval(intervalId);

                player.src(playbackUrl);
              }
            } catch (error) {}
          };
          registerIVSTech(videojs, {
            wasmWorker:
              "https://storage.googleapis.com/test-react-stokk/IVS-workers/amazon-ivs-wasmworker.min.js",
            wasmBinary:
              "https://storage.googleapis.com/test-react-stokk/IVS-workers/amazon-ivs-wasmworker.min.wasm",
          });

          const player = videojs(
            "vid1",
            {
              techOrder: ["AmazonIVS"],
              controls: true,
              playsinline: true,
              preload: "auto",
              fill: isMobile() ? false : true,
              autoplay: false,
              muted: true,
              width: window.innerWidth,
              responsive: true,
              liveui: true,
              bigPlayButton: true,
              controlBar: {
                pictureInPictureToggle: true,
                subtitlesButton: false,
                subsCapsButton: false,
              },
            },

            function onPlayerReady() {
              player.on("fullscreenchange", function () {
                if (!player.isFullscreen() && isMobile()) {
                  player.play()!;
                } else if (isMobile()) {
                  player.width(window.innerWidth);
                }
              });
              player.on("loadedmetadata", function () {
                player.play()!.catch((error: any) => {
                  if (isMobile()) {
                    setShouldDisplayVideo(true);
                    setLoadingPlayer(false);
                    setDidEventStart(true);
                  }
                });
              });
              player.on("volumechange", function () {
                const isMuted = player.muted();
                setIsVideoMuted(isMuted);
              });

              player.on("play", function () {
                setShouldDisplayVideo(true);
                setLoadingPlayer(false);
                setDidEventStart(true);
              });

              player.on("ended", function () {
                setDidEventEnd(true);
                setShouldDisplayVideo(false);
                setLoadingPlayer(false);
                setDidEventStart(false);
              });

              player.tech().on("error", function () {
                if (new Date(eventEnd).getTime() > Date.now()) {
                  setDidEventStart(false);
                  setLoadingPlayer(false);
                  setShouldDisplayVideo(false);
                  //Retry live URL every 10 seconds
                  intervalId = window.setInterval(checkUrl, 10000);
                } else {
                  setDidEventEnd(true);
                  setShouldDisplayVideo(false);
                  setLoadingPlayer(false);
                  //Retry live URL every 10 seconds
                  intervalId = window.setInterval(checkUrl, 10000);
                }
              });

              player.src(playbackUrl);

              playerRef.current = player;
              setLoadingPlayer(true);
            }
          );
        }
      } else {
        setPlayerReady(false);
      }
    } catch (error) {
      setPlayerReady(false);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [videoRef, playerRef]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const liveEventWrapper = document.querySelector(
          ".live-event-wrapper"
        ) as HTMLElement;
        if (liveEventWrapper) {
          if (!entry.isIntersecting) {
            const currentHeightVh =
              liveEventWrapper.offsetHeight / (window.innerHeight / 100);
            const newHeightVh = currentHeightVh - 10;
            liveEventWrapper.style.height = `${newHeightVh}vh`;
          } else {
            observer.unobserve(entry.target);
          }
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (questionsLinkRef.current) {
      observer.observe(questionsLinkRef.current);
    }

    return () => {
      if (questionsLinkRef.current) {
        observer.unobserve(questionsLinkRef.current);
      }
    };
  }, []);

  const scrollToQuestions = () => {
    const scrollToQuestions = document.getElementById("sort-select-questions");
    if (scrollToQuestions) {
      scrollToQuestions.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const unmute = () => {
    const player = playerRef.current;
    if (player) {
      player.muted(false);
    }
  };

  const isBeingSelected = useRef(false);
  const cursorLeftIcon = useRef(false);

  useEffect(() => {
    var page = document.getElementById("kt_app_content_container");
    var iconPlaceholder = document.querySelector(".vjs-volume-panel");
    var liveDisplay = document.querySelector(".vjs-live-display");
    if (!iconPlaceholder || !liveDisplay || !page) {
      return;
    }

    if (liveDisplay) {
      let child = liveDisplay.firstChild;
      const redDot = document.createElement("div");
      redDot.className = "red-dot";
      if (child) {
        liveDisplay.appendChild(redDot);
      }
    }
    const addHoveredClass = () => {
      cursorLeftIcon.current = false;
      liveDisplay!.classList.add("hovered");
    };

    const removeHoveredClass = () => {
      cursorLeftIcon.current = true;
      if (!isBeingSelected.current) {
        liveDisplay!.classList.remove("hovered");
      }
    };

    const handleMouseDown = () => {
      isBeingSelected.current = true;
    };

    const handleMouseUp = () => {
      if (isBeingSelected.current && cursorLeftIcon.current) {
        liveDisplay!.classList.remove("hovered");
        isBeingSelected.current = false;
      }
      isBeingSelected.current = false;
    };

    iconPlaceholder.addEventListener("mouseover", addHoveredClass);
    iconPlaceholder.addEventListener("mouseout", removeHoveredClass);
    page.addEventListener("mousedown", handleMouseDown);
    page.addEventListener("mouseup", handleMouseUp);

    return () => {
      if (!iconPlaceholder || !liveDisplay || !page) {
        return;
      }
      iconPlaceholder.removeEventListener("mouseover", addHoveredClass);
      iconPlaceholder.removeEventListener("mouseout", removeHoveredClass);
      page.removeEventListener("mousedown", handleMouseDown);
      page.removeEventListener("mouseup", handleMouseUp);
      if (liveDisplay) {
        liveDisplay.removeChild(liveDisplay.lastChild!);
      }
    };
  }, []);

  return (
    <>
      {playerReady ? (
        <>
          <div className={`${shoudlDisplayVideo ? "" : "hidden"} ${embedded && tab !== "Recording" ? "h-0 overflow-hidden" : ""}` }>
            <div
              className={` ${
                isMobile() ? "live-event-wrapper-mobile" : embedded ? "live-event-wrapper-embedded" : "live-event-wrapper"
              }`}
            >
              <div
                id="vid1"
                className={`video-js vjs-matrix mb-2 ${
                  shoudlDisplayVideo ? "" : "hidden"
                }`}
              ></div>
              {!isMobile() && isVideoMuted && (
                <div className="unmute-info">
                  <div className="card card-custom ">
                    <div className="card-header no-border px-7">
                      <h5 className="card-title display-9 fw-light me-4">
                        Video is muted
                      </h5>
                      <div className="card-toolbar">
                        <button
                          type="button"
                          className="btn btn-sm btn-light"
                          onClick={unmute}
                        >
                          Click to unmute
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {!didEventStart ? (
            <div className="live-event-wrapper">
              {loadingPlayer ? (
                <div className="d-flex flex-column flex-center px-10">
                  <h1 className="display-5">Loading the video player</h1>
                  <span className="spinner-border spinner-border-large align-middle ms-5"></span>
                </div>
              ) : (
                <>
                  {!didEventEnd ? (
                    <div className="d-flex flex-column flex-center px-10">
                      <h1 className="display-5">
                        The livestream will start soon
                      </h1>
                      <span className="spinner-border spinner-border-large align-middle ms-5"></span>
                    </div>
                  ) : (
                    <div className="d-flex flex-column flex-center w-100 px-10">
                      <h1 className="display-4">
                        The livestream has ended, thank you for watching!
                      </h1>
                      <p className="display-6 fw-medium">
                        The recording will be available shortly.
                      </p>
                    </div>
                  )}
                </>
              )}{" "}
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="live-event-wrapper">
          <div className="w-100 d-flex justify-content-center">
            <div className="alert bg-light-danger d-flex flex-column flex-sm-row p-5 me-10 ms-10 mb-10">
              <KTSVG
                path="media/icons/duotune/files/fil007.svg"
                className="svg-icon-muted svg-icon-2hx me-4 text-danger"
              />
              <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
                <h5 className="mb-1">Something went wrong!</h5>
                <span>
                  We are sorry, there has been an error when loading the
                  livestrem, please refresh the page.
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {!embedded && (
        <div ref={questionsLinkRef} className="questions-link">
          <a
            onClick={scrollToQuestions}
            className="btn d-flex flex-center btn-active-secondary btn-sm mt-2"
          >
            <i className="ki-outline ki-down fs-1"></i>
            <h1 className="m-0 fw-light">Questions</h1>
            <i className="ki-outline ki-down fs-1 ms-1"></i>
          </a>
        </div>
      )}
    </>
  );
};

export default EventLive;
