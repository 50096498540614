import { FC } from "react";
import {
  Popover as BootstrapPopover,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { applySentimentForPopup } from "../../../app/pages/event/core/_helpers";
import { useThemeMode } from "..";

interface ResizePopoverProps {
  showPopover: boolean;
  position: "top" | "right" | "bottom" | "left" | "auto" | undefined;
  hidePopover: () => void;
}

const ResizePopover: FC<ResizePopoverProps> = ({
  position,
  showPopover,
  hidePopover,
}) => {
  const { mode } = useThemeMode();
  const ResizePopover = (
    <BootstrapPopover id="popover-basic">
      <div className="d-flex">
        <BootstrapPopover.Body style={{paddingRight: 0}}>
          <p>
            Drag to see how it looks on smaller screens.
          </p>
        </BootstrapPopover.Body>
        <i className="ki-outline ki-cross fs-1 m-2" onClick={hidePopover}></i>
      </div>
    </BootstrapPopover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      show={showPopover}
      placement={position}
      overlay={ResizePopover}
      rootClose={false}
    >
      <div style={{height: 1, width: 1}}></div>
    </OverlayTrigger>
  );
};

export default ResizePopover;
