import clsx from "clsx";
import { Link, useLocation, useMatch } from "react-router-dom";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { LayoutSetup, useLayout } from "../../core";
import { Header } from "./Header";
import { Navbar } from "./Navbar";
import { useEffect } from "react";
import { useThemeMode } from "../../../partials";

export function HeaderWrapper() {
  const { config, classes } = useLayout();
  const { mode, menuMode } = useThemeMode();
  const location = useLocation();

  if (config.app?.header?.default?.container === "fluid") {
    if (!LayoutSetup.classes.headerContainer.includes("container-xxl")) {
      LayoutSetup.classes.headerContainer.push("container-fluid");
    }
  } else {
    if (!LayoutSetup.classes.headerContainer.includes("container-xxl")) {
      LayoutSetup.classes.headerContainer.push("container-xxl");
    }
  }

  if (!config.app?.header?.display) {
    return null;
  }
  useEffect(() => {
    if (config.isEventPage) {
      document.getElementById("kt_app_header")?.classList.add("dark-header");
    }
  }, []);  

  const match = useMatch("/app/:company/event/:transcriptId/:eventName");
  const matchTemp = useMatch("/app/event/:eventId");

  useEffect(() => {
    if (match || matchTemp) {
      var appContainer = document.getElementById("kt_app_header_container");
      if (appContainer) {
        appContainer.classList.add("mw-100");
        appContainer.classList.add("w-100");
        appContainer.classList.add("ms-10");
        appContainer.classList.add("me-10");
      }
    }
  }, [mode, menuMode, match]);

  return (
    <div id="kt_app_header" className="app-header">
      <div
        id="kt_app_header_container"
        className={clsx(
          "app-container",
          classes.headerContainer.join(" "),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <>
            {config.layoutType !== "dark-header" &&
            config.layoutType !== "light-header" ? (
              <div
                className="d-flex align-items-center d-lg-none ms-n2 me-2"
                title="Show sidebar menu"
              >
                <div
                  className="btn btn-icon btn-active-color-primary w-35px h-35px"
                  id="kt_app_sidebar_mobile_toggle"
                >
                  <KTIcon iconName="abstract-14" className=" fs-1" />
                </div>
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                  <Link to="/dashboard" className="d-lg-none">
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl(
                        "media/logos/stokkio/logo-off-white-icon-large.svg"
                      )}
                      className="h-30px"
                    />
                  </Link>
                </div>
              </div>
            ) : null}
          </>
        )}

        {!(
          config.layoutType === "dark-sidebar" ||
          config.layoutType === "light-sidebar"
        ) && (
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
            <Link to="/dashboard">
              {config.layoutType === "dark-header" ? (
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("media/logos/default-dark.svg")}
                  className="h-20px h-lg-30px app-sidebar-logo-default"
                />
              ) : (
                <>
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("media/logos/default.svg")}
                    className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show"
                  />
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("media/logos/default-dark.svg")}
                    className="h-20px h-lg-30px app-sidebar-logo-default theme-dark-show"
                  />
                </>
              )}
            </Link>
          </div>
        )}

        <div
          id="kt_app_header_wrapper"
          className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
        >
          {config.app.header.default?.content === "menu" &&
            config.app.header.default.menu?.display && (
              <div
                className="app-header-menu app-header-mobile-drawer align-items-stretch"
                data-kt-drawer="true"
                data-kt-drawer-name="app-header-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="225px"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_app_header_menu_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </div>
    </div>
  );
}
